import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { APP_KEYS } from '../common/consts';
import { AuthPageContainer } from '../auth';
import { HomePageContainer } from '../home';
import { Header } from '../common/components/header/Header.component';
import { SettigsPageContainer } from '../settings';
import useAuth from '../auth/feature/auth';
import { Loader, NotFound } from '../common/components';
import { ManagementContainer } from '../management';
import useLoader from '../common/feature/loader';

export function MainRouter() {
  const { isAuthenticated } = useAuth();
  const { showLoader } = useLoader();
  return (
    <Router>
      {showLoader && <Loader />}
      <div className="flex justify-center flex-col">
        <Header />
        <Switch>
          <Route
            component={isAuthenticated ? HomePageContainer : AuthPageContainer}
            path={APP_KEYS.ROUTER_KEYS.AUTH}
          />
          <Route
            component={HomePageContainer}
            path={APP_KEYS.ROUTER_KEYS.ROOT}
            exact
          />
          <Route
            component={isAuthenticated ? SettigsPageContainer : AuthPageContainer}
            path={APP_KEYS.ROUTER_KEYS.SETTINGS}
          />
          <Route
            component={isAuthenticated ? ManagementContainer : AuthPageContainer}
            path={APP_KEYS.ROUTER_KEYS.MANAGEMENT}
          />
          <Route component={NotFound} />
        </Switch>
      </div>
    </Router>
  );
}
