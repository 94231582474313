import React from 'react';

export const LockIcon = () => (
  <svg
    width="19"
    height="19"
    viewBox="0 0 19 16"
    xmlns="http://www.w3.org/2000/svg"
    className=" fill-current"
  >
    <g clipPath="url(#clip0_140_5527)">
      <path
        // eslint-disable-next-line max-len
        d="M14.5625 6.75H14V4.5C14 2.01825 11.9818 0 9.5 0C7.01825 0 5 2.01825 5 4.5V6.75H4.4375C3.5075 6.75 2.75 7.50675 2.75 8.4375V16.3125C2.75 17.2432 3.5075 18 4.4375 18H14.5625C15.4925 18 16.25 17.2432 16.25 16.3125V8.4375C16.25 7.50675 15.4925 6.75 14.5625 6.75ZM6.5 4.5C6.5 2.8455 7.8455 1.5 9.5 1.5C11.1545 1.5 12.5 2.8455 12.5 4.5V6.75H6.5V4.5ZM10.25 12.5415V14.25C10.25 14.664 9.91475 15 9.5 15C9.08525 15 8.75 14.664 8.75 14.25V12.5415C8.30375 12.2812 8 11.8027 8 11.25C8 10.4227 8.67275 9.75 9.5 9.75C10.3273 9.75 11 10.4227 11 11.25C11 11.8027 10.6962 12.2812 10.25 12.5415Z"
      />
    </g>
    <defs>
      <clipPath id="clip0_140_5527">
        <rect width="18" height="18" fill="white" transform="translate(0.5)" />
      </clipPath>
    </defs>
  </svg>
);
