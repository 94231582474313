import React from 'react';
import { useMutation } from 'react-query';
import { STORAGE_KEYS } from '../../common/consts/app-keys.const';
import { authService } from '../service/auth.service';
import useLoader from '../../common/feature/loader';

interface IAuthProviderProps {
  children: React.ReactNode;
}
// eslint-disable-next-line no-spaced-func
const Context = React.createContext<{
  isAuthenticated: null | boolean;
  isLoading: boolean;
  setIsAuthenticated: (value: null | boolean) => void;
  checkAuth: () => void;
}>({
  isAuthenticated: false,
  isLoading: true,
  setIsAuthenticated: (value: null | boolean) => {},
  checkAuth: () => {}
});

export const AuthProvider = ({ children }: IAuthProviderProps) => {
  const { toggleLoader } = useLoader();

  const [isAuthenticated, setIsAuthenticated] = React.useState<null | boolean>(null);
  const [isLoading, setIsLoading] = React.useState(true);
  const auth = useMutation(
    [],
    async (token: string) => authService.validateToken(token),
    {
      onSuccess: (result: boolean) => {
        if (result) setIsAuthenticated(true);
        setIsLoading(false);
      },
      onError: (err) => {
        setIsAuthenticated(false);
        localStorage.removeItem(STORAGE_KEYS.TOKEN);
      }
    }
  );

  async function checkAuth() {
    setIsLoading(true);
    const token = localStorage.getItem(STORAGE_KEYS.TOKEN);
    if (token) {
      auth.mutate(token);
    }
    setIsLoading(false);
  }

  React.useEffect(() => {
    checkAuth();
  }, []);
  React.useEffect(() => {
    toggleLoader(isLoading);
  }, [isLoading]);
  const value = React.useMemo(
    () => ({
      isAuthenticated,
      isLoading,
      setIsAuthenticated,
      checkAuth
    }),
    [isAuthenticated, setIsAuthenticated, isLoading]
  );

  return <Context.Provider value={value}>{children}</Context.Provider>;
};

const useAuth = () => {
  const context = React.useContext(Context);

  if (!context) {
    throw new Error('useAuth() can only be used within AuthProvider');
  }

  return context;
};

export default useAuth;
