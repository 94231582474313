export const AUTH_PATTERN = {
  email:
    /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/,
  password: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,20})/
};

export const AUTH_VALIDATION_CONFIG = {
  password: {
    minLength: 8,
    maxLength: 20
  },

  sixDigitCode: {
    length: 8
  }
};

export const PasswordErrors = {
  required: 'Password is required',
  pattern:
    'Password must contain 8-20 symbols, including at least one lower case letter, one upper case letter, one number, and one special symbol.',
  minLength: 'Password must be at least 8 characters',
  maxLength: 'Password must be at most 20 characters',
  notMatch: "The passwords don't match",
  same: 'Your new password must be different to previously used password',
  wrong: 'The password is wrong'
};

export const EmailErrors = {
  required: 'Email is required',
  pattern: 'Please provide a properly formatted email address',
  taken: 'The email is already in use',
  same: "The new email address can't be the same as the current email address"
};

export const TermsErrors = {
  required: 'Please check the box above to agree'
};

export const CodeErrors = {
  required: 'Code is required',
  incorrect: 'Code entered incorrectly',
  expired: 'Code has expired',
  alreadyCofirmed: 'Email already confirmed'
};
