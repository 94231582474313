import React from 'react';
import { Link } from 'react-router-dom';
import { ROUTER_KEYS } from '../consts/app-keys.const';
import { DisconnectIcon } from './icons';
import { Button } from './UI';

export const NotFound = () => (
  <div className="w-full min-h-screen flex justify-center align-middle relative px-10 overflow-x-hidden">
    <div className="flex flex-col sm:flex-row absolute sm:top-32 sm:w-fit gap-16 sm:gap-0">
      <div className="flex flex-col gap-4 w-96 mx-auto text-center sm:text-left sm:-mr-40 z-10 px-10 sm:px-0">
        <h1 className="text-blue-600 text-10xl leading-none -mb-4">404</h1>
        <h2 className="text-4xl">Page not found</h2>
        <span>
          Oops! The page you are looking for does not exist. It might have been moved
          or deleted
        </span>
        <Link to={ROUTER_KEYS.ROOT}>
          <Button value="Back to home" onClick={() => {}} />
        </Link>
      </div>
      <div className="overflow-x-hidden">
        <DisconnectIcon />
      </div>
    </div>
  </div>
);
