import React from 'react';
import { useHistory } from 'react-router-dom';
import successRadioSvg from '../../../assets/icons/succes-radio.svg';
import { FormCaption, InformSpan, Button } from '../../common/components';
import { ROUTER_KEYS } from '../../common/consts/app-keys.const';

export const PasswordUpdated = () => {
  const history = useHistory();

  return (
    <div className="flex flex-col justify-center gap-4">
      <img src={successRadioSvg} alt="Succes Icon" className="h-14 w-14 mx-auto" />
      <FormCaption>PASSWORD UPDATED!</FormCaption>
      <InformSpan>
        Your password has been changed successfully. Use your new password to Sign
        In.
      </InformSpan>
      <Button onClick={() => history.push(ROUTER_KEYS.SIGNIN)} value="Sign In" />
    </div>
  );
};
