import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { FormContainer, PasswordUpdated } from './components';
import {
  ActivateAccount,
  CreateNewPassword,
  ForgotPassword,
  PasswordRecovery,
  Signin,
  Signup
} from './forms';
import { APP_KEYS } from '../common/consts';

export const AuthPageContainer = () => (
  <FormContainer>
    <Switch>
      <Route component={Signup} path={APP_KEYS.ROUTER_KEYS.SIGNUP} />
      <Route component={Signin} path={APP_KEYS.ROUTER_KEYS.SIGNIN} />
      <Route
        component={ActivateAccount}
        path={APP_KEYS.ROUTER_KEYS.ACTIVATE_ACCOUNT}
      />
      <Route
        component={PasswordRecovery}
        path={APP_KEYS.ROUTER_KEYS.PASSWORD_RECOVERY}
      />
      <Route
        component={ForgotPassword}
        path={APP_KEYS.ROUTER_KEYS.FORGOT_PASSWORD}
      />
      <Route
        component={PasswordUpdated}
        path={APP_KEYS.ROUTER_KEYS.PASSWORD_UPDATED}
      />
      <Route
        component={CreateNewPassword}
        path={`${APP_KEYS.ROUTER_KEYS.CREATE_NEW_PASSWORD}/:token`}
      />

      <Route component={Signin} path="*" />
    </Switch>
  </FormContainer>
);
