import React, { useEffect } from 'react';
import { useQuery } from 'react-query';
import { toast } from 'react-hot-toast';
import { managementService } from './service/management.service';
import { Ikey } from './types.type';
import {
  ManagememtContainer,
  ManagementTable,
  FileLoad,
  PortalButton
} from './components';
import useLoader from '../common/feature/loader';
import { settingsService } from '../settings/service/settings.service';

export const ManagementContainer = () => {
  const { toggleLoader } = useLoader();
  const keysQuery = useQuery<unknown, unknown, Ikey[], 'keys'>(
    'keys',
    async () => managementService.getLicenseKeys(),
    {
      onError: (error: any) => {
        toggleLoader(false);
        toast.error("Couldn't get license keys");
      },
      onSuccess: (data) => {
        toggleLoader(false);
      }
    }
  );
  // const keysQueryUpd = useQuery<unknown, unknown, Ikey[], 'keys'>(
  //   'keys',
  //   async () => managementService.getLicenseKeys(),
  //   {
  //     onError: (error: any) => {
  //       toast.error("Couldn't get license keys");
  //     },
  //     enabled: keysQuery.isSuccess
  //   }
  // );
  const succesCond = keysQuery.isSuccess && keysQuery.data.length !== 0;
  const data = keysQuery.data!;
  const hasPortalQuery = useQuery(
    'portal',
    async () => settingsService.getUserHasPortal(),
    {}
  );
  useEffect(() => {
    toggleLoader(true);
  }, []);

  useEffect(() => {
    const t = setTimeout(() => {
      keysQuery.refetch();
    }, 1500);

    return () => {
      clearTimeout(t);
    };
  }, [succesCond]);

  return (
    <ManagememtContainer>
      {succesCond ? (
        <>
          <ManagementTable data={data} />
          <FileLoad />
          <div className="w-96 mx-auto">
            {' '}
            {hasPortalQuery.data && <PortalButton />}
          </div>
        </>
      ) : (
        <h1 className="tracking-wider text-lg">You have no subscriptions</h1>
      )}
    </ManagememtContainer>
  );
};
