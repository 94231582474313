import React from 'react';
import { Link } from 'react-router-dom';

interface IMenuButtonProps {
  text: string;
  onClick: () => void;
  to: string;
  Icon: any;
}

export const MenuButton = ({ onClick, text, to, Icon }: IMenuButtonProps) => {
  const isCurrentPage = window.location.pathname === to;
  let isCurrentStyles = 'text-gray-600';
  if (isCurrentPage) {
    isCurrentStyles = 'text-blue-600';
  }
  if (to === '') {
    return (
      <button
        type="button"
        onClick={onClick}
        className={`${isCurrentStyles} flex flex-row gap-2 hover:text-blue-500 active:text-blue-700`}
      >
        <Icon /> <span>{text}</span>
      </button>
    );
  }
  return (
    <Link to={to}>
      <button
        type="button"
        onClick={onClick}
        className={`${isCurrentStyles} flex flex-row gap-2 hover:text-blue-500 active:text-blue-700`}
      >
        <Icon /> <span>{text}</span>
      </button>
    </Link>
  );
};
