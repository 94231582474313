import React from 'react';

export const ShellIcon = () => (
  <svg
    width="18"
    height="22"
    viewBox="0 0 16 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.5 9.25H6.5L5.375 7H1.25V11.5C1.25 12.3284 1.92157 13 2.75 13H13.25C14.0784 13 14.75 12.3284 14.75 11.5V7H11L9.5 9.25Z"
      className="fill-current"
    />
    <path
      d="M1.25 7H5.375L6.5 9.25H9.5L11 7H14.75M1.25 7V11.5C1.25 12.3284 1.92157 13 2.75 13H13.25C14.0784 13 14.75 12.3284 14.75 11.5V7M1.25 7L3.3175 1.48666C3.42728 1.19393 3.70712 1 4.01975 1H11.9802C12.2929 1 12.5727 1.19393 12.6825 1.48666L14.75 7"
      className="stroke-current"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
