import React from 'react';
import { ITableProps } from '../../types.type';

export const Th = ({ children, onClick }: ITableProps) => (
  <th
    onClick={onClick}
    className="tracking-widest uppercase text-gray-500 pb-3 text-small sm:text-sm pt-6 pl-6 "
  >
    {children}
  </th>
);
