import React from 'react';

export const ArrowClock = () => (
  <svg
    width="17"
    height="21"
    viewBox="0 0 13 9"
    className="fill-none stroke-current"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.78076 5.66724H1.53076V3.41724"
      strokeWidth="0.75"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      // eslint-disable-next-line max-len
      d="M3.12451 9.9094C3.70127 10.4866 4.43627 10.8798 5.23653 11.0393C6.0368 11.1987 6.86637 11.1172 7.62031 10.8051C8.37425 10.493 9.01869 9.96428 9.4721 9.28585C9.9255 8.60743 10.1675 7.80977 10.1675 6.99378C10.1675 6.17778 9.9255 5.38013 9.4721 4.7017C9.01869 4.02327 8.37425 3.49456 7.62031 3.18245C6.86637 2.87034 6.0368 2.78885 5.23653 2.94828C4.43627 3.10772 3.70127 3.50092 3.12451 4.07815L1.53076 5.66721"
      strokeWidth="0.75"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
