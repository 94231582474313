import React from 'react';
import { Link } from 'react-router-dom';
import { ILinkText } from './types.type';

export const LinkText = ({ to, text, linkText }: ILinkText) => (
  <div className="mt-4 text-center mx-auto">
    <span className="text-gray-500 text-center mx-auto">
      {text}{' '}
      <Link
        to={to}
        className="text-black font-semibold hover:text-blue-600 active:text-blue-800"
      >
        {linkText}
      </Link>
    </span>
  </div>
);
