import { BACKEND_KEYS, STORAGE_KEYS } from '../../common/consts/app-keys.const';
import { HttpFactoryService, HttpService } from '../../common/services';
import {
  IActivateAccount,
  ISignin,
  ISignnResponse,
  IForgotPassword,
  ICheckRecoveryCode,
  IResetPassword
} from '../types';

class AuthService {
  httpService: HttpService;

  constructor(private httpFactory: HttpFactoryService) {
    this.httpService = httpFactory.createHttpService();
  }

  public async login(payload: ISignin) {
    const result: ISignnResponse | void = await this.httpService.post(
      BACKEND_KEYS.SIGNIN,
      payload
    );
    if (!result?.access_token) throw new Error('Error while signing in');

    localStorage.setItem(STORAGE_KEYS.TOKEN, result.access_token);
    return result;
  }

  public async signup(payload: ISignin) {
    const result: true | void = await this.httpService.post(
      BACKEND_KEYS.SIGNUP,
      payload
    );
    if (result !== true) throw new Error('Error while signing up');
    return result;
  }

  public async activateAccount(payload: IActivateAccount) {
    const result: ISignnResponse | void = await this.httpService.post(
      BACKEND_KEYS.ACTIVATE_ACCOUNT,
      {
        token: payload.code
      }
    );
    if (!result?.access_token) throw new Error('Error while activating account');

    localStorage.setItem(STORAGE_KEYS.TOKEN, result.access_token);

    return result;
  }

  public async forgotPassword(payload: IForgotPassword) {
    const result: true | void = await this.httpService.post(
      BACKEND_KEYS.FORGOT_PASSWORD,
      payload
    );
    if (result !== true) throw new Error('Error while sending recovery code');
    return payload.email;
  }

  public async checkRecoveryCode(payload: ICheckRecoveryCode) {
    const result: true | void = await this.httpService.post(
      BACKEND_KEYS.CHECK_RECOVERY_CODE,
      payload
    );
    if (result !== true) throw new Error('Error while checking recovery code');
    return payload.token;
  }

  public async resetPassword(payload: IResetPassword) {
    const result = await this.httpService.post(BACKEND_KEYS.PASSWORD_RESET, payload);
    if (!result) throw new Error('Error while resetting password');
    return result;
  }

  public async resendRecoveryCode(payload: IForgotPassword) {
    const result: true | void = await this.httpService.post(
      BACKEND_KEYS.RESEND_RECOVERY_CODE,
      payload
    );
    return payload.email;
  }

  public async resendActivationCode(payload: IForgotPassword) {
    const result: true | void = await this.httpService.post(
      BACKEND_KEYS.RESEND_ACTIVATION_CODE,
      payload
    );
    return payload.email;
  }

  public async validateToken(token: string): Promise<boolean> {
    const result: true | void = await this.httpService.post(
      BACKEND_KEYS.VALIDATE_TOKEN,
      {
        token
      }
    );
    if (result !== true) throw new Error('Error while validating token');
    return result;
  }
}

const authService = new AuthService(new HttpFactoryService());

export { authService };
