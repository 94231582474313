export const TOAST_SEND_CODE_CONTENT = {
  text: 'Code sent to your email',
  styles: {
    icon: '📧',
    style: {
      borderRadius: '10px',
      background: '#333',
      color: '#fff'
    }
  }
};
