import React from 'react';
import { FileLoadContainer } from './file-load-container.component';
import { ShellIcon, ZipIcon } from '../../../common/components';
import { FileSelect } from './file-select.component.component';

export const FileLoad = () => (
  <FileLoadContainer>
    <div className="flex gap-2">
      <ZipIcon />
      <span className="text-3xl font-bold pt-2">Download TouchPose</span>
    </div>
    <FileSelect />
    <a href={process.env.REACT_APP_DISK_LINK!}>
      <div className="cursor-pointer border-2 border-gray-200 w-fit px-3 py-0.5 rounded text-gray-500 flex flex-row gap-2 hover:text-blue-600 hover:border-blue-600">
        <ShellIcon />
        <span>Previous versions</span>
      </div>
    </a>
  </FileLoadContainer>
);
