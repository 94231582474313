import React from 'react';
import { ITableProps } from '../../types.type';

export const ManagememtContainer = ({ children, onClick }: ITableProps) => (
  <div className="px-6 sm:w-11/12 md:w-10/12 m-w-fit h-fit mx-auto text-center mb-24">
    <div className="flex flex-col justify-center align-middle gap-6 m-w-fit text-center">
      <h1 className="text-4xl font-semibold">Available Keys</h1>
      {children}
    </div>
  </div>
);
