import React from 'react';
import { useMutation } from 'react-query';
import { toast } from 'react-hot-toast';
import { useForm, SubmitHandler } from 'react-hook-form';
import { IPopupProps } from './types.type';
import {
  FormContainer,
  PopupContainer,
  ResendCodeButton,
  SixDigitInput
} from '../../common/components';
import { PopupButtons } from '../../common/components/popup';
import {
  CodeErrors,
  AUTH_VALIDATION_CONFIG
} from '../../auth/forms/validation.pattern';
import { settingsService } from '../service/settings.service';
import { ROUTER_KEYS, STORAGE_KEYS } from '../../common/consts/app-keys.const';

type Inputs = {
  code: string;
};

export const ConfirmEmail = ({ isOpen, onClose }: IPopupProps) => {
  const [formattedValue, setFormattedValue] = React.useState('');
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<Inputs>();
  const resend = useMutation(
    async (email: string) => settingsService.resendEmailConfirmation({ email }),
    {
      onSuccess: () => {
        toast.success('Code re-sent successfully');
      }
    }
  );

  const activate = useMutation(
    async (data: Inputs) => settingsService.confirmEmail({ code: data.code }),
    {
      onSuccess: () => {
        onClose();
        toast.success('Email changed successfully');
        localStorage.removeItem(STORAGE_KEYS.TOKEN);
        localStorage.removeItem(STORAGE_KEYS.EMAIL);
        setTimeout(() => {
          window.location.href = ROUTER_KEYS.SIGNIN;
        }, 1000);
      },
      onError: (err: any) => {
        if (err.response.data.message === 'Invalid code') {
          errors.code = {
            message: CodeErrors.incorrect,
            type: 'manual'
          };
        }
        if (err.response.data.message === 'Email already confirmed') {
          errors.code = {
            message: CodeErrors.alreadyCofirmed,
            type: 'manual'
          };
        }
        if (err.response.data.message === 'Code expired') {
          errors.code = {
            message: CodeErrors.expired,
            type: 'manual'
          };
        }
      }
    }
  );
  const onSubmit: SubmitHandler<Inputs> = (data) => {
    const code = data.code.replaceAll(' ', '');
    activate.mutate({ code });
  };

  const codeRegister = register.bind(null, 'code', {
    required: { message: CodeErrors.required, value: true },
    minLength: {
      value: AUTH_VALIDATION_CONFIG.sixDigitCode.length,
      message: CodeErrors.incorrect
    },
    maxLength: {
      value: AUTH_VALIDATION_CONFIG.sixDigitCode.length,
      message: CodeErrors.incorrect
    }
  });
  const resendCodeHandler = () => {
    const email = localStorage.getItem(STORAGE_KEYS.EMAIL);
    if (!email) return;
    resend.mutate(email);
  };
  return (
    <PopupContainer
      caption="Confirm new email"
      isOpen={isOpen}
      onClose={onClose}
      description={
        <div>
          Email confirmation code has been sent to your email{' '}
          <span className="text-black font-semibold">
            {localStorage.getItem(STORAGE_KEYS.EMAIL)}
          </span>
          , enter it in the field below:
        </div>
      }
    >
      <FormContainer onSubmit={handleSubmit(onSubmit)}>
        <SixDigitInput
          errors={errors}
          name="code"
          setFormattedValue={setFormattedValue}
          formattedValue={formattedValue}
          register={codeRegister}
        >
          {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
           */}
          <ResendCodeButton onClick={resendCodeHandler} error={!!errors.code} />
        </SixDigitInput>
        <PopupButtons onClose={onClose} text="Confirm" />
      </FormContainer>
    </PopupContainer>
  );
};
