// Local storage keys
export const STORAGE_KEYS = {
  JWT_TOKEN_STUDENT: 'JWT_TOKEN_STUDENT',
  JWT_TOKEN_INSTRUCTOR: 'JWT_TOKEN_INSTRUCTOR',
  ADDRESS: 'ADDRESS',
  TOKEN: 'TOKEN',
  EMAIL: 'EMAIL'
};

// React-query keys
export const QUERY_KEYS = {
  USER: 'user',
  RECOVERY_CODE: 'code'
};

// Backend Routes
export const BACKEND_KEYS = {
  // auth
  SIGNUP: 'auth/signup',
  SIGNIN: 'auth/signin',
  ACTIVATE_ACCOUNT: 'auth/activate',
  PASSWORD_RESET: 'auth/reset-password',
  FORGOT_PASSWORD: 'auth/password-recovery',
  CHECK_RECOVERY_CODE: 'auth/check-password-recovery-code',
  RESEND_RECOVERY_CODE: 'auth/resend-password-recovery',
  RESEND_ACTIVATION_CODE: 'auth/resend-activation',
  VALIDATE_TOKEN: 'auth/validate-token',
  // settings
  USER_INFO: 'user/has-portal',
  CHANGE_EMAIL: 'user/change-email',
  CHANGE_PASSWORD: 'user/change-password',
  DELETE_ACCOUNT: 'user/delete-account',
  RESEND_EMAIL_CONFIRMATION: 'user/resend-email-confirmation',
  CONFiRM_EMAIL: 'user/confirm-email',
  // stripe
  CHECKOUT_SESSION: 'stripe/session',
  GET_USER_PORTAL_URL: 'stripe/customer-portal',
  GET_FREE_KEY: 'stripe/free-key',
  GET_PLAN_PRICE: 'stripe/price',
  // management
  GET_LICENSE_KEYS: 'get-license-keys',
  PLUGIN: 'user/plugin',
  // dashboard
  PRODUCT: 'spring/products/db-one'
};

export const ROUTER_KEYS = {
  ROOT: '/',
  AUTH: '/auth',
  SIGNUP: '/auth/signup',
  ACTIVATE_ACCOUNT: '/auth/activate-account',
  PASSWORD_RECOVERY: '/auth/password-recovery',
  SIGNIN: '/auth/signin',
  FORGOT_PASSWORD: '/auth/forgot-password',
  CREATE_NEW_PASSWORD: '/auth/create-new-password',
  PASSWORD_UPDATED: '/auth/password-updated',
  AUTHORIZED: 'authorized',
  SETTINGS: '/settings',
  MANAGEMENT: '/management'
};
