import React from 'react';
import { useMutation } from 'react-query';
import { STORAGE_KEYS } from '../consts/app-keys.const';

interface IPopupProviderProps {
  children: React.ReactNode;
}
// eslint-disable-next-line no-spaced-func
function noop(): void {
  throw new Error('Function not loaded.');
}

// eslint-disable-next-line no-spaced-func
const Context = React.createContext<{
  handleModal: (CurrPopup: any) => void;
  modal: React.ReactNode | null;
  showPopup: boolean;
  setModal: (modal: React.ReactNode) => void;
  setShowPopup: (showPopup: boolean) => void;
}>({
  handleModal: noop,
  modal: null,
  showPopup: false,
  setModal: noop,
  setShowPopup: noop
});

export const PopupProvider = ({ children }: IPopupProviderProps) => {
  const [modal, setModal] = React.useState<React.ReactNode>(null);
  const [showPopup, setShowPopup] = React.useState(false);

  const handleModal = (CurrPopup: any) => {
    const popupComp = <CurrPopup isOpen onClose={setShowPopup.bind(null, false)} />;
    setModal(popupComp);
    setShowPopup(true);
  };

  const value = React.useMemo(
    () => ({
      handleModal,
      modal,
      showPopup,
      setModal,
      setShowPopup
    }),
    [handleModal, modal, showPopup, setModal, setShowPopup]
  );

  return <Context.Provider value={value}>{children}</Context.Provider>;
};

const usePopup = () => {
  const context = React.useContext(Context);

  if (!context) {
    throw new Error('usePopup() can only be used within AuthProvider');
  }

  return context;
};

export default usePopup;
