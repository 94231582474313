import React from 'react';

interface Props {
  children: React.ReactNode;
}

export const DropdownContainerMobile = ({ children }: Props) => (
  <div className="z-50 bg-gray-100 shadow p-4 pt-12 absolute rounded-lg flex flex-col gap-2 w-screen min-h-full b-10 left-0 top-16">
    {children}
  </div>
);
