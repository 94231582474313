import React from 'react';

export const CopyIcon = () => (
  <svg
    width="18"
    height="19"
    viewBox="0 0 18 19"
    className="fill-gray-500 hover:fill-black cursor-pointer"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      // eslint-disable-next-line max-len
      d="M13.36 1.5H6.16571C5.73228 1.5 5.31659 1.67299 5.0101 1.9809C4.70361 2.28882 4.53143 2.70645 4.53143 3.14191V3.77341H3.71429C3.25963 3.77341 2.82359 3.95487 2.5021 4.27786C2.18061 4.60085 2 5.03892 2 5.49569V15.7777C2 16.2345 2.18061 16.6726 2.5021 16.9956C2.82359 17.3185 3.25963 17.5 3.71429 17.5H10.7543C11.2089 17.5 11.645 17.3185 11.9665 16.9956C12.288 16.6726 12.4686 16.2345 12.4686 15.7777V15.2036H13.36C13.79 15.2037 14.2029 15.034 14.5097 14.7312C14.8164 14.4284 14.9925 14.0167 15 13.5847V3.14191C14.9985 2.70592 14.825 2.28831 14.5176 1.98055C14.2102 1.6728 13.794 1.5 13.36 1.5ZM11.3257 15.7777C11.3257 15.93 11.2655 16.076 11.1583 16.1837C11.0512 16.2913 10.9058 16.3518 10.7543 16.3518H3.71429C3.56273 16.3518 3.41739 16.2913 3.31022 16.1837C3.20306 16.076 3.14286 15.93 3.14286 15.7777V5.49569C3.14286 5.34344 3.20306 5.19741 3.31022 5.08975C3.41739 4.98209 3.56273 4.9216 3.71429 4.9216H10.7543C10.9058 4.9216 11.0512 4.98209 11.1583 5.08975C11.2655 5.19741 11.3257 5.34344 11.3257 5.49569V15.7777ZM13.8571 13.5847C13.8556 13.7161 13.8026 13.8417 13.7095 13.9341C13.6165 14.0266 13.4909 14.0784 13.36 14.0784H12.4686V5.49569C12.4686 5.03892 12.288 4.60085 11.9665 4.27786C11.645 3.95487 11.2089 3.77341 10.7543 3.77341H5.67429V3.14191C5.67429 3.01097 5.72606 2.88539 5.81822 2.7928C5.91038 2.70021 6.03538 2.64819 6.16571 2.64819H13.36C13.425 2.64743 13.4895 2.65964 13.5498 2.68411C13.6101 2.70858 13.665 2.74483 13.7112 2.79075C13.7574 2.83667 13.7941 2.89136 13.8192 2.95163C13.8442 3.01191 13.8571 3.07659 13.8571 3.14191V13.5847Z"
    />
  </svg>
);
