import React from 'react';
import { useMutation, QueryCache, QueryClient } from 'react-query';
import { PopupContainer } from '../../common/components';
import { PopupButtons } from '../../common/components/popup';
import { STORAGE_KEYS, ROUTER_KEYS } from '../../common/consts/app-keys.const';
import { IPopupProps } from './types.type';
import { settingsService } from '../service/settings.service';
import { queryClient } from '../../app/queryClient';

export const DeleteAccount = ({ isOpen, onClose }: IPopupProps) => {
  const logout = () => {
    localStorage.removeItem(STORAGE_KEYS.EMAIL);
    localStorage.removeItem(STORAGE_KEYS.TOKEN);
    queryClient.clear();
    window.location.href = ROUTER_KEYS.SIGNIN;
  };
  const deleteQuery = useMutation(
    async () => {
      settingsService.deleteAccount();
    },
    {
      onSuccess: () => {
        logout();
      }
    }
  );
  const deleteHandler = () => {
    deleteQuery.mutate();
  };

  return (
    <PopupContainer
      caption="Do you really want to delete  your account?"
      isOpen={isOpen}
      onClose={onClose}
    >
      <PopupButtons onClick={deleteHandler} text="Delete" onClose={onClose} />
    </PopupContainer>
  );
};
