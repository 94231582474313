import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import logoIcon from '../../../../assets/icons/logo-squarebit-store.svg';
import logoShortIcon from '../../../../assets/icons/logo-short.svg';
import { ROUTER_KEYS } from '../../consts/app-keys.const';
import useAuth from '../../../auth/feature/auth';
import { HeaderButtons } from './header-buttons.component';
import { UserIcon } from '../icons';
import { HeaderMenuDropdown } from './dropdown';
import usePopup from '../../feature/popup';

export const Header = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [isDropdownVisible, setIsDropdownVisible] = useState<boolean>(false);
  const [prevScrollPos, setPrevScrollPos] = useState<number>(window.pageYOffset);
  const [isVisible, setIsVisible] = useState<boolean>(true);
  const { isLoading, isAuthenticated } = useAuth();
  const { modal, showPopup } = usePopup();

  const showDropdownHandler = () => {
    setIsDropdownVisible(!isDropdownVisible);
  };
  useEffect(() => {
    function handleResize() {
      setWindowWidth(window.innerWidth);
    }
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);
  useEffect(() => {
    if (isDropdownVisible) {
      document.body.addEventListener('click', showDropdownHandler);
    }

    return () => {
      document.body.removeEventListener('click', showDropdownHandler);
    };
  }, [isDropdownVisible]);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.pageYOffset;
      setIsVisible(prevScrollPos > currentScrollPos);
      setPrevScrollPos(currentScrollPos);
    };
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [prevScrollPos]);

  const logoDisplayed = windowWidth > 626 ? logoIcon : logoShortIcon;
  let rightSide: React.ReactNode =
    isAuthenticated === true ? (
      <div
        id="user-icon"
        onClick={showDropdownHandler}
        className="cursor-pointer text-blue-600 hover:text-blue-400 active:text-blue-700"
      >
        <UserIcon />
      </div>
    ) : (
      <HeaderButtons />
    );

  if (isLoading) rightSide = null;

  let headerStyles = `flex justify-between mb-10 h-16 bg-white w-full px-16 py-3 z-50 transition duration-300 shadow ${
    isVisible ? 'opacity-100' : 'opacity-100 transform -translate-y-full'
  }`;

  if (windowWidth < 425) {
    headerStyles =
      'flex justify-between mb-10 h-16 bg-white w-full px-16 py-3 z-50 transition duration-300 shadow';
  }

  return (
    <>
      {showPopup && modal}
      <div className={headerStyles}>
        {isDropdownVisible && <HeaderMenuDropdown onClose={showDropdownHandler} />}
        <Link to={ROUTER_KEYS.ROOT}>
          <img
            src={logoDisplayed}
            alt="logo"
            className={`${windowWidth > 626 ? '' : 'mt-2'}`}
          />
        </Link>
        {rightSide}
      </div>
    </>
  );
};
