import React from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';
import { useMutation } from 'react-query';
import {
  FormCaption,
  InformSpan,
  FormSubmit,
  Input,
  ArrowLink
} from '../../common/components';
import {
  AUTH_PATTERN,
  PasswordErrors,
  AUTH_VALIDATION_CONFIG
} from './validation.pattern';
import { ROUTER_KEYS, STORAGE_KEYS } from '../../common/consts/app-keys.const';
import { APP_KEYS } from '../../common/consts';
import { authService } from '../service/auth.service';
import { IResetPassword } from '../types';

type Inputs = {
  password: string;
  confirmPassword: string;
};

export const CreateNewPassword = () => {
  const history = useHistory();
  const { token } = useParams<{ token: string }>();
  const {
    register,
    watch,
    handleSubmit,
    formState: { errors }
  } = useForm<Inputs>();

  const resetPassword = useMutation(
    [APP_KEYS.QUERY_KEYS.USER],
    async (data: IResetPassword) => authService.resetPassword(data),

    {
      onSuccess: async () => {
        history.push(ROUTER_KEYS.PASSWORD_UPDATED);
      },
      onError: (err: any) => {
        if (
          err.response.data.message === 'Password cannot be the same as the old one'
        ) {
          errors.password = {
            message: PasswordErrors.same,
            type: 'manual'
          };
        } else if (err.response.data.message === 'Invalid Password') {
          errors.password = {
            message: PasswordErrors.wrong,
            type: 'manual'
          };
        }
      }
    }
  );

  const onSubmit: SubmitHandler<Inputs> = (dataInputs: Inputs) => {
    const email = localStorage.getItem(STORAGE_KEYS.EMAIL);
    if (!email) return;
    resetPassword.mutate({
      email,
      password: dataInputs.password,
      token
    });
  };

  return (
    <>
      <FormCaption> Create a new password </FormCaption>
      <InformSpan>
        Your new password must be different to previously used passwords.
      </InformSpan>
      <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col mt-7">
        <Input
          errors={errors}
          name="password"
          label="Create password"
          placeholder="Create new password"
          type="password"
          register={register.bind(null, 'password', {
            required: { message: PasswordErrors.required, value: true },
            pattern: {
              value: AUTH_PATTERN.password,
              message: PasswordErrors.pattern
            },
            minLength: {
              value: AUTH_VALIDATION_CONFIG.password.minLength,
              message: PasswordErrors.minLength
            },
            maxLength: {
              value: AUTH_VALIDATION_CONFIG.password.maxLength,
              message: PasswordErrors.maxLength
            }
          })}
        />
        <Input
          errors={errors}
          name="confirmPassword"
          label="Re-enter password"
          placeholder="Re-enter your password"
          type="password"
          register={register.bind(null, 'confirmPassword', {
            required: { message: PasswordErrors.required, value: true },
            pattern: {
              value: AUTH_PATTERN.password,
              message: PasswordErrors.pattern
            },
            minLength: {
              value: AUTH_VALIDATION_CONFIG.password.minLength,
              message: PasswordErrors.minLength
            },
            maxLength: {
              value: AUTH_VALIDATION_CONFIG.password.maxLength,
              message: PasswordErrors.maxLength
            },
            validate: {
              validate: (val: string) => {
                if (watch('password') !== val) {
                  return 'Your passwords do no match';
                }
              }
            }
          })}
        />

        <FormSubmit value="Save password" />
        <ArrowLink to={ROUTER_KEYS.SIGNIN} linkText="Back to sign in" />
      </form>
    </>
  );
};
