import React from 'react';

export const ArrowLeft = () => (
  <svg
    width="20"
    height="24"
    viewBox="0 0 21 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.6226 10.7178H4.95593M4.95593 10.7178L10.7893 16.5512M4.95593 10.7178L10.7893 4.88449"
      strokeWidth="1.66667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
