import React from 'react';
import { IButton } from './types.type';

export const Button = ({ onClick, value }: IButton) => (
  // eslint-disable-next-line jsx-a11y/control-has-associated-label
  <button
    type="button"
    value={value}
    onClick={onClick}
    className="cursor-pointer mt-3 uppercase font-semibold bg-blue-600 text-white mx-auto h-11 rounded-md w-full  active:bg-blue-700 transition hover:bg-blue-500"
  >
    {value}
  </button>
);
