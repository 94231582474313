import React from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { useMutation } from 'react-query';
import { useHistory } from 'react-router-dom';
import {
  FormCaption,
  InformSpan,
  FormSubmit,
  Input,
  ArrowLink
} from '../../common/components';
import { ROUTER_KEYS, STORAGE_KEYS } from '../../common/consts/app-keys.const';
import { EmailErrors, AUTH_PATTERN } from './validation.pattern';
import { authService } from '../service/auth.service';
import { APP_KEYS } from '../../common/consts';

type Inputs = {
  email: string;
};

export const ForgotPassword = () => {
  const history = useHistory();
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<Inputs>();

  const getRecoveryCode = useMutation(
    [APP_KEYS.QUERY_KEYS.RECOVERY_CODE],
    async (data: Inputs) => authService.forgotPassword(data),

    {
      onSuccess: async (email: string) => {
        localStorage.setItem(STORAGE_KEYS.EMAIL, email);
        history.push(ROUTER_KEYS.PASSWORD_RECOVERY);
      },
      onError: (err: any) => {
        if (err.response.data.message === 'User not found') {
          errors.email = {
            message: 'Account with this email address not found',
            type: 'manual'
          };
        }
      }
    }
  );

  const onSubmit: SubmitHandler<Inputs> = (data: Inputs) => {
    getRecoveryCode.mutate(data);
  };

  return (
    <>
      <FormCaption> Forgot a password? </FormCaption>
      <InformSpan>No worries, we’ll send you reset instructions.</InformSpan>
      <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col mt-7">
        <Input
          errors={errors}
          name="email"
          label="Email"
          placeholder="Enter your email"
          type="email"
          register={register.bind(null, 'email', {
            required: { message: EmailErrors.required, value: true },
            pattern: {
              value: AUTH_PATTERN.email,
              message: EmailErrors.pattern
            }
          })}
        />

        <FormSubmit value="Reset Password" />

        <ArrowLink linkText="Create account" to={ROUTER_KEYS.SIGNUP} />
      </form>
    </>
  );
};
