import React from 'react';
import {
  EmaiIcon,
  LockIcon,
  ArrowRightIcon,
  LogoutIcon,
  PopupContainer
} from '../common/components';
import { ChangePassword, EditEmail, Logout } from './popups';

export const SettingsButtonsData = [
  {
    text: 'Edit email address',
    icon: <EmaiIcon />,
    isReverse: false,
    fill: false,
    popup: EditEmail
  },
  {
    text: 'Change password',
    icon: <LockIcon />,
    isReverse: false,
    fill: false,
    popup: ChangePassword
  },
  {
    text: 'Manage your subscriptions',
    icon: <ArrowRightIcon />,
    isReverse: true,
    fill: true
  },
  {
    text: '',
    icon: <LogoutIcon />,
    isReverse: false,
    fill: false,
    popup: Logout
  }
];
