import React from 'react';
import toast from 'react-hot-toast';
import { useMutation, useQuery } from 'react-query';
import { useHistory } from 'react-router-dom';
import { MenuButton } from './ui';
import { CancelIcon } from '../common/components';
import { SmallButton } from './ui/small-button.component';
import { STORAGE_KEYS } from '../common/consts/app-keys.const';
import { SettingsButtonsData } from './button.data';
import { DeleteAccount } from './popups';
import usePopup from '../common/feature/popup';
import { managementService } from '../management/service/management.service';
import { settingsService } from './service/settings.service';
import useLoader from '../common/feature/loader';

export const SettigsPageContainer = () => {
  const history = useHistory();
  const { toggleLoader } = useLoader();
  const { handleModal, modal, showPopup } = usePopup();
  const portalQuery = useMutation(() => managementService.getUserPortalUrl(), {
    onSuccess: (url) => {
      window.location.href = url;
    },
    onError: () => {
      toast.error('You have no subscriptions');
    }
  });

  const hasPortalQuery = useQuery(
    'portal',
    async () => settingsService.getUserHasPortal(),
    {}
  );
  const portalHandler = (): void => {
    portalQuery.mutate();
  };
  const closeHandler = (): void => {
    history.goBack();
  };
  if (hasPortalQuery.isLoading) {
    toggleLoader(true);
  } else {
    toggleLoader(false);
  }
  return (
    <>
      {showPopup && modal}
      {hasPortalQuery.data !== undefined && (
        <div className="flex justify-center">
          <div className="flex w-full sm:w-4/5 md:w-3/5 lg:w-2/5 laptop_l:w-1/3 flex-col justify-center gap-5  relative mx-4 mb-10">
            <button
              onClick={closeHandler}
              type="button"
              className="absolute right-5 top-3 text-black border-2 border-black rounded hover:border-blue-500 active:text-blue-700 active:border-blue-700 hover:text-blue-500"
            >
              <CancelIcon />
            </button>

            <div className="flex flex-col gap-2">
              <h1 className="text-4xl font-semibold">Settings</h1>
              <div>
                <span className="font-semibold">Current email address: </span>

                <span className="text-gray-500">
                  {localStorage.getItem(STORAGE_KEYS.EMAIL)}
                </span>
              </div>
            </div>

            <div className="flex flex-col gap-3">
              {SettingsButtonsData.map(
                ({ text, icon, isReverse, fill, popup }, i) => (
                  <MenuButton
                    key={i}
                    text={text}
                    isReverse={isReverse}
                    icon={icon}
                    fill={fill}
                    onClick={() => (popup ? handleModal(popup) : portalHandler())}
                  />
                )
              )}
            </div>
            <div className="flex flex-col gap-3 mt-6">
              <h3 className="text-2xl font-semibold text-gray-500">
                Terms of website use
              </h3>
              <a href="https://www.squarebitstudios.com/products/touchpose/touchpose_eula">
                <SmallButton text="Terms and conditions" type="gray" />
              </a>
            </div>
            <div className="flex flex-col gap-3 mt-6">
              <h3 className="text-2xl font-semibold text-gray-500">
                Delete Account
              </h3>
              <span className="text-gray-500">
                Permanently delete your account and all data associated with it. If
                you experienced an issue with your account and need help, please
                contact us so we can assist you.
              </span>
              <SmallButton
                text="Delete my account"
                type="danger"
                onClick={() => {
                  handleModal(DeleteAccount);
                }}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};
