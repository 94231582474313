import React from 'react';
import { ISixDigitInputProps } from '../../types/input.type';
import { ErrorSpan } from './error-span.component';

export const SixDigitInput = ({
  errors,
  name,
  register,
  children,
  setFormattedValue,
  formattedValue
}: ISixDigitInputProps) => {
  const handleChange = (event: { target: { value: string } }) => {
    const inputValue = event.target.value;
    const numericValue = inputValue.replace(/\D/g, '');
    const formatted = numericValue.replace(/(\d{2})(\d{2})(\d{2})/, '$1 $2 $3');
    setFormattedValue(formatted);
  };

  return (
    <div className="relative">
      <label htmlFor="code" className="capitalize">
        Your Code
        <input
          placeholder="00 00 00"
          type="text"
          name="code"
          id="code"
          value={formattedValue}
          className={errors[`${name}`] && 'errorInput'}
          {...register()}
          onChange={handleChange}
        />
      </label>

      {children}
      {errors[`${name}`] && <ErrorSpan>{errors[`${name}`].message}</ErrorSpan>}
    </div>
  );
};
