import React from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { useMutation } from 'react-query';
import { toast } from 'react-hot-toast';
import { FormContainer, Input, PopupContainer } from '../../common/components';
import {
  AUTH_PATTERN,
  AUTH_VALIDATION_CONFIG,
  PasswordErrors
} from '../../auth/forms/validation.pattern';
import { PopupButtons } from '../../common/components/popup';
import { settingsService } from '../service/settings.service';
import { ROUTER_KEYS, STORAGE_KEYS } from '../../common/consts/app-keys.const';

interface IProps {
  onClose: () => void;
  isOpen: boolean;
}

type Inputs = {
  password: string;
  newPassword: string;
  confirmNewPassword: string;
};

export const ChangePassword = ({ isOpen, onClose }: IProps) => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors }
  } = useForm<Inputs>();
  const password = useMutation(
    async (data: Inputs) => settingsService.changePassword(data),
    {
      onSuccess: () => {
        onClose();
        toast.success('Password changed successfully');
        localStorage.removeItem(STORAGE_KEYS.TOKEN);
        setTimeout(() => {
          window.location.href = ROUTER_KEYS.SIGNIN;
        }, 1000);
      },
      onError: (err: any) => {
        if (
          err.response.data.message === 'Password cannot be the same as the old one'
        ) {
          errors.newPassword = {
            message: PasswordErrors.same,
            type: 'manual'
          };
        } else if (err.response.data.message === 'Invalid Password') {
          errors.password = {
            message: PasswordErrors.wrong,
            type: 'manual'
          };
        }
      }
    }
  );

  const onSubmit: SubmitHandler<Inputs> = (data: Inputs) => {
    password.mutate(data);
  };

  return (
    <PopupContainer
      caption="Change Password"
      isOpen={isOpen}
      onClose={onClose}
      description="Your new password must be different to previously used passwords."
    >
      <FormContainer onSubmit={handleSubmit(onSubmit)}>
        <Input
          errors={errors}
          name="password"
          label="Current password"
          placeholder="Enter old password"
          type="password"
          register={register.bind(null, 'password', {
            required: { message: PasswordErrors.required, value: true },
            pattern: {
              value: AUTH_PATTERN.password,
              message: PasswordErrors.pattern
            },
            minLength: {
              value: AUTH_VALIDATION_CONFIG.password.minLength,
              message: PasswordErrors.minLength
            },
            maxLength: {
              value: AUTH_VALIDATION_CONFIG.password.maxLength,
              message: PasswordErrors.maxLength
            }
          })}
        />
        <Input
          errors={errors}
          name="newPassword"
          label="New password"
          placeholder="Enter new password"
          type="password"
          register={register.bind(null, 'newPassword', {
            required: { message: PasswordErrors.required, value: true },
            pattern: {
              value: AUTH_PATTERN.password,
              message: PasswordErrors.pattern
            },
            minLength: {
              value: AUTH_VALIDATION_CONFIG.password.minLength,
              message: PasswordErrors.minLength
            },
            maxLength: {
              value: AUTH_VALIDATION_CONFIG.password.maxLength,
              message: PasswordErrors.maxLength
            },
            validate: (value) => value !== watch('password') || PasswordErrors.same
          })}
        />
        <Input
          errors={errors}
          name="confirmNewPassword"
          label="Confirm new Password"
          placeholder="Re-enter your password"
          type="password"
          register={register.bind(null, 'confirmNewPassword', {
            required: { message: PasswordErrors.required, value: true },
            pattern: {
              value: AUTH_PATTERN.password,
              message: PasswordErrors.pattern
            },
            minLength: {
              value: AUTH_VALIDATION_CONFIG.password.minLength,
              message: PasswordErrors.minLength
            },
            maxLength: {
              value: AUTH_VALIDATION_CONFIG.password.maxLength,
              message: PasswordErrors.maxLength
            },
            validate: (value) =>
              value === watch('newPassword') || PasswordErrors.notMatch
          })}
        />
        <PopupButtons onClose={onClose} text="Update password" />
      </FormContainer>
    </PopupContainer>
  );
};
