import React from 'react';
import { Loader } from '../components';

interface ILoaderProviderProps {
  children: React.ReactNode;
}
// eslint-disable-next-line no-spaced-func
function noop(): void {
  throw new Error('Function not loaded.');
}

// eslint-disable-next-line no-spaced-func
const Context = React.createContext<{
  toggleLoader: (show: boolean) => void;
  showLoader: boolean;
}>({
  toggleLoader: noop,
  showLoader: false
});

export const LoaderProvider = ({ children }: ILoaderProviderProps) => {
  const [showLoader, setShowLoader] = React.useState<boolean>(false);

  const toggleLoader = (show: boolean) => {
    if (show === showLoader) {
      return;
    }
    setShowLoader(show);
  };

  const value = React.useMemo(
    () => ({
      toggleLoader,
      showLoader
    }),
    [toggleLoader, showLoader]
  );

  return <Context.Provider value={value}>{children}</Context.Provider>;
};

const useLoader = () => {
  const context = React.useContext(Context);

  if (!context) {
    throw new Error('useLoader() can only be used within AuthProvider');
  }

  return context;
};

export default useLoader;
