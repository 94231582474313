import React from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { Link, useHistory } from 'react-router-dom';
import { useMutation } from 'react-query';
import { ISignin } from '../types/auth.types';
import { Input } from '../../common/components/form/input.component';
import {
  CheckBox,
  FormCaption,
  FormSubmit,
  InformSpan,
  LinkText
} from '../../common/components';
import {
  EmailErrors,
  AUTH_PATTERN,
  PasswordErrors,
  AUTH_VALIDATION_CONFIG
} from './validation.pattern';
import { ROUTER_KEYS } from '../../common/consts/app-keys.const';
import { APP_KEYS } from '../../common/consts';
import { authService } from '../service/auth.service';
import useAuth from '../feature/auth';

type Inputs = {
  email: string;
  password: string;
  remember: any;
};

export const Signin = () => {
  const { setIsAuthenticated } = useAuth();
  const history = useHistory();
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors }
  } = useForm<Inputs>();

  const signin = useMutation(
    [APP_KEYS.QUERY_KEYS.USER],
    async (data: ISignin) => authService.login(data),

    {
      onSuccess: async () => {
        localStorage.setItem(APP_KEYS.STORAGE_KEYS.EMAIL, watch('email'));
        setIsAuthenticated(true);
        history.push(ROUTER_KEYS.ROOT);
      },
      onError: (err: any) => {
        if (err.response.data.message === 'User not found') {
          errors.email = {
            message: 'Account with this email address not found',
            type: 'manual'
          };
        } else if (err.response.data.message === 'Credentials incorrect') {
          errors.password = {
            message: 'Password is incorrect',
            type: 'manual'
          };
        }
      }
    }
  );

  const onSubmit: SubmitHandler<Inputs> = (data: ISignin) => {
    signin.mutate(data);
  };

  return (
    <>
      <FormCaption> sign in </FormCaption>
      <InformSpan>Please enter your contact details to connect.</InformSpan>
      <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col mt-7">
        <Input
          errors={errors}
          name="email"
          label="Email"
          placeholder="Enter your email"
          type="email"
          register={register.bind(null, 'email', {
            required: { message: EmailErrors.required, value: true },
            pattern: {
              value: AUTH_PATTERN.email,
              message: EmailErrors.pattern
            }
          })}
        />
        <Input
          errors={errors}
          name="password"
          label="Password"
          placeholder="Enter your password"
          type="password"
          register={register.bind(null, 'password', {
            required: { message: PasswordErrors.required, value: true },
            pattern: {
              value: AUTH_PATTERN.password,
              message: PasswordErrors.pattern
            },
            minLength: {
              value: AUTH_VALIDATION_CONFIG.password.minLength,
              message: PasswordErrors.minLength
            },
            maxLength: {
              value: AUTH_VALIDATION_CONFIG.password.maxLength,
              message: PasswordErrors.maxLength
            }
          })}
        />
        <div className="flex justify-between align-middle items-baseline">
          <CheckBox
            description="Remember me"
            register={register.bind(null, 'remember')}
          />
          <Link
            to={ROUTER_KEYS.FORGOT_PASSWORD}
            className="text-blue-500 cursor-pointer hover:text-blue-600 active:text-blue-800"
          >
            Forgot Password
          </Link>
        </div>

        <FormSubmit value="sign in" />
        <LinkText
          linkText="Create account"
          to={ROUTER_KEYS.SIGNUP}
          text="New to Squarebit Store?"
        />
      </form>
    </>
  );
};
