import React from 'react';
import { toast } from 'react-hot-toast';
import { CopyIcon } from '../../../common/components';
import { Table, Tr, Th, Td } from '../ui';
import { Ikey } from '../../types.type';
import { shortString, transformDateString } from '../../util';

interface IProps {
  data: Ikey[];
}

export const ManagementTableMobile = ({ data }: IProps) => (
  <div className="shadow rounded-lg -ml-1.5 overflow-hidden">
    <Table>
      <Tr styles="bg-white">
        <Th>№</Th>
        <Th>key code</Th>
        <Th>renews on / Expires at</Th>
        <Th>{}</Th>
      </Tr>
      {data.map(({ key, productName, expiresAt, type }, index) => {
        const date = transformDateString(expiresAt);
        const keyShort = shortString(key);
        return (
          <>
            <Tr styles="text-lg" key={`row${index}`}>
              <Td key={`empty${index}`} />
              <td className="py-1 pl-6" key={`name${index}`}>
                <span className=" tracking-widest">{productName}</span>
              </td>
              <td />
              <td className="capitalize">{type}</td>
            </Tr>
            <Tr key={index} styles="bg-white f-full">
              <Td>{index + 1}</Td>
              <Td>{keyShort}</Td>
              <Td>
                <div className="text-blue-500 text-center">{date}</div>
              </Td>
              <Td>
                <div
                  onClick={() => {
                    navigator.clipboard.writeText(key);
                    toast.success('Key copied to clipboard');
                  }}
                >
                  <CopyIcon />
                </div>
              </Td>
            </Tr>
          </>
        );
      })}
    </Table>
  </div>
);
