import React from 'react';
import { Link } from 'react-router-dom';
import { ROUTER_KEYS } from '../../consts/app-keys.const';

export const HeaderButtons = () => (
  <div className="flex gap-2">
    <Link to={ROUTER_KEYS.SIGNUP}>
      <button
        type="button"
        className="font-semibold border-2 rounded-xl w-20 px-2 py-2 border-blue-600 bg-blue-600 text-white  hover:bg-blue-700 hover:border-blue-700"
      >
        Register
      </button>
    </Link>
    <Link to={ROUTER_KEYS.SIGNIN}>
      <button
        type="button"
        className="font-semibold border-2 rounded-xl w-20 px-2 py-2 border-blue-600 text-blue-600  hover:border-blue-400 hover:text-blue-400 active:text-blue-700 active:border-blue-700"
      >
        Log In
      </button>
    </Link>
  </div>
);
