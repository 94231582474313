import React from 'react';
import toast from 'react-hot-toast';
import { useMutation } from 'react-query';
import { ArrowRightIcon } from '../../common/components';
import { MenuButton } from '../../settings/ui';
import { managementService } from '../service/management.service';

export const PortalButton = () => {
  const portalQuery = useMutation(() => managementService.getUserPortalUrl(), {
    onSuccess: (url) => {
      window.location.href = url;
    },
    onError: () => {
      toast.error("Can't get portal url");
    }
  });
  const portalHandler = (): void => {
    portalQuery.mutate();
  };

  return (
    <div className="mx-auto flex justify-center" onClick={portalHandler}>
      <MenuButton
        text="Manage your subscriptions"
        icon={<ArrowRightIcon />}
        isReverse
        fill
      />
    </div>
  );
};
