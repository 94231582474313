import React from 'react';
import { InformSpan } from '../../form';
import { STORAGE_KEYS } from '../../../consts/app-keys.const';
import { HeadersData } from './header-menu.data';
import usePopup from '../../../feature/popup';
import { MenuButton } from './dropdown.component';
import { Container } from './responsive-container.comonent';
import { CancelIcon } from '../../icons';

interface IProps {
  onClose: () => void;
}

export const HeaderMenuDropdown = ({ onClose }: IProps) => {
  const [width, setWidth] = React.useState(window.innerWidth);
  const email = localStorage.getItem(STORAGE_KEYS.EMAIL);
  const { handleModal } = usePopup();
  React.useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  return (
    <Container>
      {width < 525 && (
        <div
          onClick={onClose}
          className="absolute right-6 top-6 hover:text-blue-600"
        >
          <CancelIcon />
        </div>
      )}
      <div>
        <h3 className="text-xl font-semibold">Squarebit Store</h3>
        <InformSpan>{email}</InformSpan>
      </div>
      <div className="w-full h-px bg-transparent mobile_l:bg-slate-300" />
      <div className="flex flex-col gap-1">
        {HeadersData.map((data, i) => {
          let { to } = data;
          const handle = () => {
            if (data.popup) {
              handleModal(data.popup);
              to = '';
            } else {
              onClose();
            }
          };
          return (
            <MenuButton
              key={i}
              onClick={handle}
              text={data.text}
              to={data.to}
              Icon={data.icon}
            />
          );
        })}
      </div>
    </Container>
  );
};
