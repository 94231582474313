import React from 'react';

export const ArrowRightIcon = () => (
  <svg
    width="19"
    height="28"
    viewBox="0 0 16 16"
    fill="none"
    className=" fill-current"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      // eslint-disable-next-line max-len
      d="M13.9579 8.71457C14.0906 8.64806 14.2022 8.54594 14.2801 8.41963C14.3581 8.29332 14.3994 8.14781 14.3994 7.99937C14.3994 7.85093 14.3581 7.70542 14.2801 7.57911C14.2022 7.4528 14.0906 7.35068 13.9579 7.28417L2.75787 1.68417C2.619 1.61469 2.46291 1.58712 2.30864 1.60483C2.15437 1.62254 2.00858 1.68477 1.88908 1.78392C1.76957 1.88308 1.6815 2.01486 1.63562 2.16321C1.58973 2.31156 1.58801 2.47006 1.63067 2.61937L2.77387 6.61937C2.82168 6.78655 2.92267 6.9336 3.06154 7.03825C3.2004 7.14291 3.36958 7.19947 3.54347 7.19937L7.20027 7.19937C7.41244 7.19937 7.61592 7.28366 7.76595 7.43369C7.91598 7.58371 8.00027 7.7872 8.00027 7.99937C8.00027 8.21154 7.91598 8.41503 7.76595 8.56506C7.61592 8.71509 7.41244 8.79937 7.20027 8.79937H3.54347C3.36958 8.79927 3.2004 8.85583 3.06154 8.96049C2.92267 9.06514 2.82168 9.21219 2.77387 9.37937L1.63147 13.3794C1.58873 13.5286 1.59034 13.6871 1.63612 13.8355C1.6819 13.9838 1.76986 14.1157 1.88927 14.2149C2.00869 14.3141 2.15439 14.3765 2.30863 14.3943C2.46286 14.4121 2.61895 14.3847 2.75787 14.3154L13.9579 8.71537V8.71457Z"
    />
  </svg>
);
