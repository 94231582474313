import React from 'react';
import { DropdownContainer } from './header-dropdown-container.component';
import { DropdownContainerMobile } from './header-dropdown-container-mobile.component';

interface Props {
  children: React.ReactNode;
}

export const Container = ({ children }: Props) => {
  const [width, setWidth] = React.useState(window.innerWidth);

  React.useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  if (width < 525) {
    return <DropdownContainerMobile>{children}</DropdownContainerMobile>;
  }

  return <DropdownContainer>{children}</DropdownContainer>;
};
