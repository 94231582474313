import { BACKEND_KEYS } from '../../common/consts/app-keys.const';
import {
  HttpFactoryService,
  EnhancedWithAuthHttpService
} from '../../common/services';
import { IEditEmail } from '../types';

class SettingsService {
  httpService: EnhancedWithAuthHttpService;

  constructor(private httpFactory: HttpFactoryService) {
    this.httpService = httpFactory.createAuthHttpService();
  }

  public async changeEmail(payload: IEditEmail) {
    const result: { email: string; token: string } | void =
      await this.httpService.post(BACKEND_KEYS.CHANGE_EMAIL, payload);

    if (!result) throw new Error('Error while changing email');
    return result;
  }

  async changePassword(payload: { password: string; newPassword: string }) {
    const result: boolean | void = await this.httpService.post(
      BACKEND_KEYS.CHANGE_PASSWORD,
      payload
    );

    if (!result) throw new Error('Error while changing password');

    return result;
  }

  async confirmEmail(payload: { code: string }) {
    const result: { email: string; token: string } | void =
      await this.httpService.post(BACKEND_KEYS.CONFiRM_EMAIL, payload);

    if (!result) throw new Error('Error while confirming email');

    return result;
  }

  async resendEmailConfirmation(payload: { email: string }) {
    const result: boolean | void = await this.httpService.post(
      BACKEND_KEYS.RESEND_EMAIL_CONFIRMATION,
      payload
    );
    return result;
  }

  async deleteAccount() {
    const result: boolean | void = await this.httpService.delete(
      BACKEND_KEYS.DELETE_ACCOUNT
    );

    if (!result) throw new Error('Error while deleting account');

    return result;
  }

  async getUserHasPortal() {
    const result: boolean | void = await this.httpService.get(
      BACKEND_KEYS.USER_INFO
    );
    return result;
  }
}

const settingsService = new SettingsService(new HttpFactoryService());

export { settingsService };
