import React from 'react';
import { Toaster } from 'react-hot-toast';
import { QueryClientProvider } from 'react-query';
import { AuthProvider } from '../auth/feature/auth';
import { LoaderProvider } from '../common/feature/loader';
import { PopupProvider } from '../common/feature/popup';
import { queryClient } from './queryClient';

export const Providers = ({ children }: { children: JSX.Element }) => (
  <QueryClientProvider client={queryClient}>
    <LoaderProvider>
      <AuthProvider>
        <PopupProvider>{children}</PopupProvider>
      </AuthProvider>
    </LoaderProvider>
    <Toaster position="top-right" reverseOrder />
  </QueryClientProvider>
);
