import React from 'react';
import { useQueries } from 'react-query';
import { WindowsIcon, MacIcon, LinuxIcon } from '../../../common/components';
import { managementService } from '../../service/management.service';
import { ButtonContainer } from './button-container.component';

export const FileSelect = () => {
  const filesQueries = useQueries([
    {
      queryKey: 'file-windows',
      queryFn: () => managementService.getFile('windows')
    },
    {
      queryKey: 'file-mac',
      queryFn: () => managementService.getFile('mac')
    },
    {
      queryKey: 'file-linux',
      queryFn: () => managementService.getFile('linux')
    }
  ]);

  return (
    <div className="flex flex-col sm:flex-row gap-4 flex-wrap">
      <ButtonContainer
        href={filesQueries[0].data!}
        downloadName={process.env.REACT_APP_WINDOWS_FILE!}
      >
        <WindowsIcon />
      </ButtonContainer>

      <ButtonContainer
        href={filesQueries[1].data!}
        downloadName={process.env.REACT_APP_MAC_FILE!}
      >
        <MacIcon />
      </ButtonContainer>
      <ButtonContainer
        href={filesQueries[2].data!}
        downloadName={process.env.REACT_APP_LINUX_FILE!}
      >
        <LinuxIcon />
      </ButtonContainer>
    </div>
  );
};
