import React from 'react';
import toast from 'react-hot-toast';
import { CopyIcon } from '../../../common/components';
import { Ikey } from '../../types.type';
import { transformDateString } from '../../util';
import { Table, Tr, Th, Td } from '../ui';

interface IProps {
  data: Ikey[];
}

export const ManagementTableDesktop = ({ data }: IProps) => (
  <div className="bg-white shadow rounded-lg -ml-1">
    <Table>
      <Tr>
        <Th>№</Th>
        <Th>product name</Th>
        <Th>Type</Th>
        <Th>Key code</Th>
        <Th>renews on / Expires at</Th>
      </Tr>
      {data.map(({ key, productName, expiresAt, type }, index) => {
        const date = transformDateString(expiresAt);

        return (
          <Tr key={index}>
            <Td>{index + 1}</Td>
            <Td>
              <span className="text-blue-500 tracking-wide md:tracking-wider lg:tracking-widest">
                {productName}
              </span>
            </Td>
            <Td>{type}</Td>
            <Td>
              <span className="line-clamp-6">{key}</span>
            </Td>
            <Td>
              <div className="text-blue-500">{date}</div>
            </Td>
            <Td>
              <div
                onClick={() => {
                  navigator.clipboard.writeText(key);
                  toast.success('Key copied to clipboard');
                }}
              >
                <CopyIcon />
              </div>
            </Td>
          </Tr>
        );
      })}
    </Table>
  </div>
);
