import React from 'react';
import { ICheckboxProps } from '../../types/input.type';

export const CheckBox = ({ description, register, terms }: ICheckboxProps) => (
  <div className="flex align-middle gap-2 mb-5 leading-4 mt-3 hover:!text-blue-600 active:!text-blue-800">
    {/* eslint-disable-next-line react/jsx-tag-spacing */}
    <input
      type="checkbox"
      className="border-gray-300 h-4 w-4 rounded-lg cursor-pointer"
      {...register()}
    />
    {terms ? (
      <a href="https://www.squarebitstudios.com/products/touchpose/touchpose_eula">
        <span className="text-gray-500 hover:text-blue-600 active:text-blue-800 cursor-pointer text-small hover:underline">
          {description}
        </span>
      </a>
    ) : (
      <span className="text-gray-500 hover:text-blue-600 active:text-blue-800 cursor-pointer text-small hover:underline">
        {description}
      </span>
    )}
  </div>
);
