import React from 'react';
import { useQuery } from 'react-query';
import { SmallButton } from '../settings/ui/small-button.component';
import logoSgv from '../../assets/icons/logo-short.svg';
import { homeService } from './service/home.service';
import useLoader from '../common/feature/loader';

interface IProps {
  onClick: () => void;
}

export const AvailablePlugins = ({ onClick }: IProps) => {
  const handleBuySubscription = () => {
    onClick();
  };
  const { toggleLoader } = useLoader();
  const productQuery = useQuery('products', async () => homeService.getProduct());
  if (productQuery.isLoading) {
    toggleLoader(true);
  } else {
    toggleLoader(false);
  }

  const product = productQuery.isSuccess
    ? {
        name: productQuery.data!.name,
        description: productQuery.data!.description
      }
    : {
        name: 'Loading',
        description:
          "TouchPose enables users to interact with rig controls by selecting them directly on the character's geometry. The user is freed from hard to select curves that clutter up your Maya scene. Includes a robust editor for quickly connecting your rig controls to sets of faces on your character's geometry."
      };
  return (
    <div className="w-screen px-4 min-h-screen  laptop_l:min-h-max laptop_l:mb-40">
      <div className="w-full flex justify-center align-middle flex-col gap-10">
        <h1 className="uppercase text-3xl font-semibold mx-auto">
          Available Products
        </h1>
        <div className="bg-white rounded-lg shadow flex min-h-fit flex-col p-3 mobile_s:p-10  gap-6 mx:4 sm:mx-auto">
          <div>
            <img src={logoSgv} alt="Logo" className="h-20" />
          </div>
          <h1 className="capitalize text-2xl font-semibold">{product.name}</h1>
          <p className="text-gray-600 max-w-xl">{product.description}</p>

          <SmallButton
            text="Buy Subscription"
            buttonType="button"
            type="filled"
            onClick={handleBuySubscription}
          />
        </div>
      </div>
    </div>
  );
};
