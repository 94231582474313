import React from 'react';
import { ITableProps } from '../../types.type';

export const Tr = ({ children, onClick, styles }: ITableProps) => (
  <tr
    onClick={onClick}
    className={`border-b-gray-300 border-b-2 md:text-lg  ${styles} w-full `}
  >
    {children}
  </tr>
);
