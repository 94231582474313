import React from 'react';

interface IProps {
  children: React.ReactNode;
  downloadName: string;
  href: string;
}

export const ButtonContainer = ({ children, downloadName, href }: IProps) => {
  const content =
    href === '' ? (
      // If 'href' is empty, render a link that goes to a disk link
      <a href={process.env.REACT_APP_DISK_LINK!}>
        <div className="p-2 px-3 border-gray-400 border-2 rounded-xl hover:border-blue-600 hover:text-blue-600 text-gray-400 w-36 cursor-pointer">
          {children}
        </div>
      </a>
    ) : (
      // If 'href' is not empty, render a link for downloading a file
      <a download={downloadName} href={href}>
        <div className="p-2 px-3 border-gray-400 border-2 rounded-xl hover:border-blue-600 hover:text-blue-600 text-gray-400 w-36 cursor-pointer">
          {children}
        </div>
      </a>
    );

  // Return the content (either link to a disk or download link)
  return content;
};
