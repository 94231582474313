import React from 'react';

export const CancelIcon = () => (
  <svg
    width="28"
    height="28"
    viewBox="0 0 22 22"
    className=" fill-current stroke-current"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.30469 5.30615L15.9165 15.918"
      strokeWidth="1.3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.30469 15.9204L15.9165 5.30859"
      strokeWidth="1.3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
