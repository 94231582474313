import React from 'react';
import { SmallButton } from '../../../settings/ui/small-button.component';

interface IProps {
  onClose: () => void;
  text: string;
  onClick?: () => void;
}

export const PopupButtons = ({ onClick, onClose, text }: IProps) => (
  <div className="flex flex-row gap-3 mt-6 justify-between w-full">
    <SmallButton text="Cancel" type="default" onClick={onClose} />
    <SmallButton text={text} type="filled" buttonType="submit" onClick={onClick} />
  </div>
);
