import React from 'react';

interface IProps {
  children: React.ReactNode;
  onSubmit: () => void;
}
export const FormContainer = ({ children, onSubmit }: IProps) => (
  <form onSubmit={onSubmit} className="flex flex-col mt-7 gap-4">
    {children}
  </form>
);
