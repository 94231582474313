import { BACKEND_KEYS, STORAGE_KEYS } from '../../common/consts/app-keys.const';
import {
  HttpFactoryService,
  EnhancedWithAuthHttpService
} from '../../common/services';
import { IProduct } from '../types';

class HomeService {
  httpService: EnhancedWithAuthHttpService;

  constructor(private httpFactory: HttpFactoryService) {
    this.httpService = httpFactory.createAuthHttpService();
  }

  async getCheckoutSession(duration: '1m' | '1y' | 'free') {
    const result: string | void = await this.httpService.get(
      BACKEND_KEYS.CHECKOUT_SESSION,
      { params: { duration } }
    );
    if (!result) throw new Error('Error while getting checkout session');

    return result;
  }

  async getProduct() {
    const result: IProduct | void = await this.httpService.get(BACKEND_KEYS.PRODUCT);
    if (!result) throw new Error('Error while getting product');
    return result;
  }

  async getFreeKey() {
    const result: string | void = await this.httpService.get(
      BACKEND_KEYS.GET_FREE_KEY
    );
    if (!result) throw new Error('Error while getting free key');

    return result;
  }

  async getPlanPrice(duration: '1m' | '1y' | 'free') {
    if (duration === 'free') return 0;
    const result: number | void = await this.httpService.get(
      BACKEND_KEYS.GET_PLAN_PRICE,
      { params: { duration } }
    );
    if (!result) throw new Error('Error while getting plan price');

    const price = (result / 100).toFixed(2);
    return price;
  }
}

const homeService = new HomeService(new HttpFactoryService());

export { homeService };
