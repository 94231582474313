import React from 'react';
import { useMutation, useQuery } from 'react-query';
import { toast } from 'react-hot-toast';
import { useHistory } from 'react-router-dom';
import useAuth from '../auth/feature/auth';
import { SmallButton } from '../settings/ui/small-button.component';
import { homeService } from './service/home.service';
import { ROUTER_KEYS } from '../common/consts/app-keys.const';

interface IProps {
  caption: string;
  duration: string;
  description: string;
  plan: '1m' | '1y' | 'free';
}

export const SubscriptionPlan = ({
  caption,
  duration,
  plan,
  description
}: IProps) => {
  const history = useHistory();
  const { isAuthenticated } = useAuth();
  const price = useQuery(['price', plan], async () =>
    homeService.getPlanPrice(plan)
  );

  const getSessionUrl = useMutation(
    'getSessionUrl',
    () => homeService.getCheckoutSession(plan),
    {
      onSuccess: (data) => {
        window.location.href = data;
      },
      onError: (error: any) => {
        toast.error("Couldn't get checkout session");
      }
    }
  );
  const getKey = useMutation(
    'getKey',
    () => homeService.getFreeKey(),

    {
      onSuccess: (data) => {
        toast.success(
          'Key generated successfully. If the new key is not yet available, refresh the page'
        );
        history.push(ROUTER_KEYS.MANAGEMENT);
      },
      onError: (error: any) => {
        toast.error('You have already taken advantage of the free trial');
      }
    }
  );

  function buySubscriptionHandler(): void {
    if (isAuthenticated) {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      plan === 'free' ? getKey.mutate() : getSessionUrl.mutate();
      return;
    }
    history.push(ROUTER_KEYS.SIGNUP);
  }

  return (
    <div className="bg-white rounded-lg shadow flex md:w-80 flex-col p-4 md:p-10 gap-6 mb-8">
      <div>
        <h1 className="capitalize text-2xl font-semibold mb-1">{caption}</h1>
        <p className="text-gray-600 min-w-subscription">{description}</p>
      </div>
      <div className="flex w-1/3 gap-1">
        <span className="text-4xl font-bold">${price.data}</span>
        <span className=" text-gray-600 leading-4 my-auto">
          per <br />
          {duration}
        </span>
      </div>
      <div>
        <SmallButton
          text={price.data === 0 ? 'Download' : 'Buy Subscription'}
          buttonType="button"
          type="filled"
          onClick={buySubscriptionHandler}
        />
      </div>
    </div>
  );
};
