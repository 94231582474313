import React from 'react';

export const UserIcon = () => (
  <svg
    width="40"
    height="40"
    viewBox="0 0 28 28"
    className="fill-current"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      // eslint-disable-next-line max-len
      d="M14 27.5625C21.4904 27.5625 27.5625 21.4904 27.5625 14C27.5625 6.50964 21.4904 0.4375 14 0.4375C6.50964 0.4375 0.4375 6.50964 0.4375 14C0.4375 21.4904 6.50964 27.5625 14 27.5625Z"
      fill="#F5F5F5"
    />
    <path
      // eslint-disable-next-line max-len
      d="M24.8836 22.0827C23.6187 20.3893 21.9762 19.0142 20.0867 18.0668C18.1973 17.1193 16.1129 16.6257 13.9992 16.625C11.8855 16.6243 9.80073 17.1167 7.91066 18.0629C6.02059 19.0092 4.37728 20.3832 3.11133 22.0759C4.37085 23.7771 6.01147 25.1596 7.90165 26.1124C9.79183 27.0652 11.879 27.5619 13.9957 27.5625C16.1125 27.5632 18.1999 27.0678 20.0907 26.1162C21.9815 25.1646 23.623 23.7832 24.8836 22.0827Z"
    />
    <path
      // eslint-disable-next-line max-len
      d="M14 14.875C16.8995 14.875 19.25 12.5245 19.25 9.625C19.25 6.72551 16.8995 4.375 14 4.375C11.1005 4.375 8.75 6.72551 8.75 9.625C8.75 12.5245 11.1005 14.875 14 14.875Z"
    />
  </svg>
);
