import React from 'react';

export const DisconnectIcon = () => (
  <svg
    width="545"
    height="188"
    viewBox="0 0 545 188"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M405.388 53.639C408.699 54.8659 412.317 54.9477 415.759 54.1584C430.183 50.8599 479.778 42.1221 471.161 80.4272C469.283 88.026 440.238 130.589 481.602 146.124C484.634 147.277 487.839 147.907 491.082 147.985C503.819 148.246 529.057 145.248 539.619 134.533C552.38 121.587 538.439 127.726 538.439 127.726C538.439 127.726 489.929 148.825 472.794 126.439C470.888 123.949 469.673 120.331 469.326 117.217C468.723 111.818 471.128 104.684 474.287 98.4713C478.565 90.0648 499.096 52.2484 454.517 41.2326C444.249 38.795 429.333 36.607 410.103 44.6762C398.884 49.3835 401.369 52.1503 405.388 53.639Z"
      fill="#BDD6F9"
    />
    <path
      d="M415.636 39.554L402.636 44.4322C398.15 46.1156 395.873 51.1263 397.553 55.6235L398.197 57.3504C399.877 61.8476 404.876 64.1293 409.362 62.446L422.364 57.5698C426.85 55.8865 429.127 50.8758 427.447 46.3765L426.803 44.6517C425.123 40.1524 420.124 37.8706 415.636 39.554Z"
      fill="#2060EB"
    />
    <path
      opacity="0.4"
      d="M336.054 53.275L293.78 69.2092C291.566 70.042 290.444 72.5241 291.273 74.7518L291.44 75.201C292.267 77.4287 294.732 78.5589 296.946 77.7261L339.22 61.7918C341.434 60.957 342.556 58.4749 341.727 56.2473L341.56 55.798C340.733 53.5704 338.268 52.4401 336.054 53.275Z"
      fill="#ABABAB"
    />
    <path
      opacity="0.4"
      d="M348.055 85.275L305.778 101.208C303.566 102.043 302.444 104.525 303.273 106.753L303.438 107.202C304.267 109.43 306.732 110.56 308.944 109.725L351.221 93.7922C353.433 92.9573 354.555 90.4752 353.728 88.2474L353.561 87.7982C352.734 85.5704 350.267 84.4401 348.055 85.275Z"
      fill="#ABABAB"
    />
    <path
      d="M387.405 31.5522L352.641 44.5436C348.15 46.2215 345.873 51.2179 347.553 55.7004L359.424 87.3689C361.104 91.8514 366.106 94.1256 370.595 92.4478L405.361 79.4564C409.85 77.7785 412.127 72.7842 410.447 68.2997L398.578 36.6311C396.898 32.1486 391.896 29.8744 387.405 31.5522Z"
      fill="#5895F9"
    />
    <path
      d="M356.229 39.5513L321.614 52.5228C317.145 54.1978 314.878 59.1837 316.551 63.6607L330.65 101.378C332.323 105.853 337.302 108.124 341.771 106.449L376.386 93.4792C380.855 91.8042 383.122 86.8183 381.449 82.3413L367.35 44.6237C365.677 40.1467 360.698 37.8762 356.229 39.5513Z"
      fill="#699CFF"
    />
    <path
      d="M361.945 32.3647L361.679 32.4649C358.751 33.5732 357.265 36.8736 358.361 39.8346L377.769 92.2797C378.865 95.2407 382.126 96.7436 385.054 95.6353L385.321 95.5331C388.251 94.4247 389.735 91.1264 388.639 88.1654L369.233 35.7203C368.137 32.7594 364.873 31.2564 361.945 32.3647Z"
      fill="#2060EB"
    />
    <path
      d="M9.91118 66.1394C9.91118 66.1394 79.2229 46.6937 69.16 91.4961C67.4124 98.585 42.2567 135.084 72.2209 152.608C78.655 156.371 86.0799 158.038 93.5363 157.999C106.905 157.93 128.098 155.361 137.619 145.688C150.379 132.72 136.44 138.869 136.44 138.869C136.44 138.869 96.6327 155.216 76.1536 142.185C69.1609 137.736 65.9912 129.122 67.8901 121.062C68.8607 117.06 70.3385 113.197 72.2882 109.567C76.742 101.239 97.0972 63.2698 52.5186 52.2362C42.2494 49.7947 27.3341 47.6031 8.10243 55.6853C-11.1293 63.7696 9.91118 66.1394 9.91118 66.1394Z"
      fill="#BDD6F9"
    />
    <path
      d="M146.637 128.554L133.637 133.432C129.15 135.115 126.873 140.126 128.553 144.625L129.197 146.35C130.877 150.849 135.876 153.13 140.363 151.445L153.363 146.569C157.851 144.886 160.127 139.875 158.447 135.376L157.803 133.651C156.123 129.152 151.124 126.871 146.637 128.554Z"
      fill="#2060EB"
    />
    <path
      d="M168.951 107.548L149.648 114.703C145.152 116.369 142.871 121.328 144.554 125.781L153.862 150.406C155.544 154.859 160.553 157.118 165.049 155.452L184.352 148.297C188.848 146.631 191.129 141.672 189.446 137.219L180.139 112.594C178.456 108.141 173.447 105.882 168.951 107.548Z"
      fill="#5895F9"
    />
    <path
      d="M200.23 88.5533L171.614 99.3189C167.145 101 164.878 106.004 166.551 110.498L180.647 148.355C182.32 152.849 187.299 155.128 191.768 153.447L220.384 142.681C224.855 141 227.122 135.996 225.45 131.502L211.352 93.6445C209.68 89.1509 204.701 86.8721 200.23 88.5533Z"
      fill="#699CFF"
    />
    <path
      d="M206.946 80.3647L206.679 80.4649C203.749 81.5732 202.265 84.8736 203.361 87.8346L222.767 140.28C223.863 143.241 227.127 144.744 230.055 143.635L230.321 143.533C233.249 142.425 234.735 139.126 233.639 136.163L214.231 83.7203C213.135 80.7593 209.874 79.2564 206.946 80.3647Z"
      fill="#2060EB"
    />
    <path
      d="M279.524 46.9897C278.504 46.875 277.568 46.3689 276.912 45.574C276.259 44.7791 275.935 43.7609 276.011 42.7303L278.123 3.89356C278.25 1.57373 280.195 -0.188846 282.404 0.0162369L285.481 0.303065C287.689 0.508149 289.271 2.5977 288.961 4.90156L283.771 43.4515C283.653 44.4779 283.144 45.4183 282.353 46.078C281.561 46.7377 280.547 47.0635 279.524 46.9897Z"
      fill="#418DF9"
    />
    <path
      d="M261.078 49.8963C260.752 49.9902 260.411 50.0208 260.073 49.9861C259.737 49.9494 259.409 49.8493 259.113 49.69C258.814 49.5328 258.553 49.3184 258.34 49.059C258.127 48.8017 257.968 48.5036 257.872 48.1871L250.133 24.1073C249.908 23.4232 249.971 22.6779 250.305 22.0387C250.641 21.3975 251.221 20.9135 251.923 20.693L253.926 20.1008C254.27 20.0028 254.631 19.9763 254.986 20.0212C255.341 20.0661 255.682 20.1825 255.989 20.3642C256.296 20.5439 256.561 20.7849 256.768 21.0708C256.974 21.3567 257.118 21.6814 257.189 22.0244L262.926 46.6964C263.086 47.358 262.984 48.0564 262.642 48.6506C262.299 49.2428 261.742 49.688 261.078 49.8963Z"
      fill="#418DF9"
    />
    <path
      d="M251.227 64.7494C249.855 66.4345 247.093 66.414 244.967 64.7043L207.236 34.3416C204.981 32.5275 204.317 29.5259 205.773 27.7404L207.803 25.2506C209.26 23.4632 212.25 23.6106 214.383 25.5721L250.086 58.4266C252.098 60.2755 252.6 63.0663 251.227 64.7494Z"
      fill="#418DF9"
    />
    <path
      d="M268.429 143.217C269.4 143.589 270.187 144.318 270.625 145.252C271.065 146.186 271.119 147.252 270.78 148.225L258.706 185.13C257.985 187.334 255.621 188.534 253.506 187.769L250.558 186.705C248.445 185.941 247.43 183.519 248.327 181.38L263.347 145.54C263.73 144.58 264.469 143.803 265.417 143.37C266.363 142.937 267.442 142.882 268.429 143.217Z"
      fill="#418DF9"
    />
    <path
      d="M286.345 144.001C286.666 143.991 286.988 144.049 287.289 144.169C287.588 144.289 287.864 144.469 288.098 144.701C288.332 144.93 288.52 145.207 288.651 145.514C288.781 145.82 288.855 146.151 288.863 146.488L289.997 172.034C290.032 172.763 289.792 173.474 289.325 174.016C288.859 174.561 288.205 174.888 287.509 174.927L285.523 174.999C285.182 175.008 284.845 174.943 284.53 174.809C284.215 174.674 283.931 174.474 283.693 174.219C283.457 173.965 283.273 173.663 283.154 173.33C283.037 172.996 282.986 172.643 283.003 172.289L283.856 146.67C283.874 145.979 284.141 145.321 284.601 144.827C285.063 144.332 285.685 144.038 286.345 144.001Z"
      fill="#418DF9"
    />
    <path
      d="M300.477 133.642C302.22 132.346 304.857 133.072 306.453 135.287L334.811 174.616C336.503 176.965 336.374 180.069 334.524 181.443L331.946 183.358C330.097 184.732 327.271 183.824 325.73 181.36L299.951 140.115C298.502 137.794 298.733 134.937 300.477 133.642Z"
      fill="#418DF9"
    />
  </svg>
);
