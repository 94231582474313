import React from 'react';
import { ROUTER_KEYS } from '../../../consts/app-keys.const';
import { GearIcon, KeyIcon, LogoutIcon } from '../../icons';
import { Logout } from '../../../../settings/popups';

interface IHeaderData {
  text: string;
  to: string;
  icon: React.ReactNode;
  popup?: React.ReactNode;
}

export const HeadersData: IHeaderData[] = [
  {
    text: 'Product management',
    to: ROUTER_KEYS.MANAGEMENT,
    icon: KeyIcon
  },
  {
    text: 'User settings',
    to: ROUTER_KEYS.SETTINGS,
    icon: GearIcon
  },
  {
    text: '',
    to: '',
    icon: LogoutIcon,
    popup: Logout
  }
];
