import React from 'react';
import { Link } from 'react-router-dom';
import { IArrowLink } from './types.type';
import { ArrowLeft } from '../icons';

export const ArrowLink = ({ to, linkText }: IArrowLink) => (
  <div className="mt-4 text-center mx-auto ">
    <span className="text-black text-center mx-auto relative flex flex-row stroke-black font-semibold hover:stroke-blue-600 active:stroke-blue-800 hover:text-blue-600 active:text-blue-800">
      <ArrowLeft />
      <Link to={to} className=" ">
        {linkText}
      </Link>
    </span>
  </div>
);
