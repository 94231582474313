import React from 'react';

export const ZipIcon = () => (
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10 0C8.625 0 7.5 1.125 7.5 2.5V37.5C7.5 38.875 8.625 40 10 40H35C36.375 40 37.5 38.875 37.5 37.5V10L27.5 0H10Z"
      fill="#E2E5E7"
    />
    <path d="M30 10H37.5L27.5 0V7.5C27.5 8.875 28.625 10 30 10Z" fill="#B0B7BD" />
    <path d="M37.5 17.5L30 10H37.5V17.5Z" fill="#CAD1D8" />
    <path
      d="M32.5 32.5C32.5 33.1875 31.9375 33.75 31.25 33.75H3.75C3.0625 33.75 2.5 33.1875 2.5 32.5V20C2.5 19.3125 3.0625 18.75 3.75 18.75H31.25C31.9375 18.75 32.5 19.3125 32.5 20V32.5Z"
      fill="#2060EB"
    />
    <path
      d="M10.3626 29.9996C9.73257 29.9996 9.48257 29.3909 9.84257 28.9609L13.4013 24.2334H10.4513C9.58257 24.2334 9.65257 23.0059 10.4513 23.0059H14.4801C15.2388 23.0059 15.4588 23.7234 14.9201 24.3046L11.5113 28.7309H14.7601C15.5601 28.7309 15.6601 30.0009 14.6813 30.0009H10.3626V29.9996Z"
      fill="white"
    />
    <path
      d="M16.5664 23.6839C16.5664 22.8639 17.8864 22.8339 17.8864 23.6839V29.3902C17.8864 30.2189 16.5664 30.2402 16.5664 29.3902V23.6839Z"
      fill="white"
    />
    <path
      d="M19.6577 23.6837C19.6577 23.3537 19.9177 22.9937 20.3377 22.9937H22.6465C23.9465 22.9937 25.1165 23.8637 25.1165 25.5312C25.1165 27.1112 23.9465 27.9912 22.6465 27.9912H20.9777V29.3112C20.9777 29.7512 20.6977 29.9999 20.3377 29.9999C20.0077 29.9999 19.6577 29.7512 19.6577 29.3112V23.6837ZM20.9765 24.2524V26.7424H22.6452C23.3152 26.7424 23.8452 26.1512 23.8452 25.5312C23.8452 24.8324 23.3152 24.2524 22.6452 24.2524H20.9765Z"
      fill="white"
    />
    <path
      d="M31.25 33.75H7.5V35H31.25C31.9375 35 32.5 34.4375 32.5 33.75V32.5C32.5 33.1875 31.9375 33.75 31.25 33.75Z"
      fill="#CAD1D8"
    />
  </svg>
);
