import { BACKEND_KEYS, STORAGE_KEYS } from '../../common/consts/app-keys.const';
import {
  HttpFactoryService,
  EnhancedWithAuthHttpService
} from '../../common/services';
import { Ikey } from '../types.type';

class ManagementService {
  httpService: EnhancedWithAuthHttpService;

  constructor(private httpFactory: HttpFactoryService) {
    this.httpService = httpFactory.createAuthHttpService();
  }

  async getLicenseKeys() {
    const response: Ikey[] | void = await this.httpService.get(
      BACKEND_KEYS.GET_LICENSE_KEYS
    );
    return response;
  }

  async getUserPortalUrl() {
    const portalUrl: string | void = await this.httpService.get(
      BACKEND_KEYS.GET_USER_PORTAL_URL
    );

    if (!portalUrl) throw new Error('Error getting portal');

    return portalUrl;
  }

  async getFile(os: string): Promise<string> {
    const endpoint = `${process.env.REACT_APP_BACKEND_URL}/api/v1/${BACKEND_KEYS.PLUGIN}?os=${os}`;
    const token = localStorage.getItem(STORAGE_KEYS.TOKEN);

    const response = await fetch(endpoint, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (response.status === 204) return '';

    const fileBlob = await response.blob();
    return URL.createObjectURL(fileBlob);
  }
}

const managementService = new ManagementService(new HttpFactoryService());

export { managementService };
