import React from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { useMutation } from 'react-query';
import { toast } from 'react-hot-toast';

import {
  ArrowClock,
  ArrowLink,
  FormCaption,
  FormSubmit,
  InformSpan,
  Input,
  SixDigitInput
} from '../../common/components';
import { ROUTER_KEYS, STORAGE_KEYS } from '../../common/consts/app-keys.const';
import { APP_KEYS } from '../../common/consts';
import { authService } from '../service/auth.service';
import ArrowCounterClockwiseSvg from '../../../assets/icons/arrow-counter-clockwise.svg';
import { CodeErrors, AUTH_VALIDATION_CONFIG } from './validation.pattern';
import { TOAST_SEND_CODE_CONTENT } from '../consts';

interface Inputs {
  code: string;
}

export const PasswordRecovery = () => {
  const [formattedValue, setFormattedValue] = React.useState('');
  const history = useHistory();
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<Inputs>();

  const sendRecoveryCode = useMutation(
    [APP_KEYS.QUERY_KEYS.RECOVERY_CODE],
    async (data: Inputs) => authService.checkRecoveryCode({ token: data.code }),

    {
      onSuccess: async (token) => {
        history.push(`${ROUTER_KEYS.CREATE_NEW_PASSWORD}/${token}`);
      },
      onError: (err: any) => {
        if (err.response.data.message === 'Invalid token') {
          errors.code = {
            message: 'Code entered incorrectly',
            type: 'manual'
          };
        }
      }
    }
  );
  const resendCode = useMutation(
    [APP_KEYS.QUERY_KEYS.RECOVERY_CODE],
    async (data: { email: string }) => authService.resendRecoveryCode(data),

    {
      onSuccess: async () => {
        toast.success(TOAST_SEND_CODE_CONTENT.text);
      }
    }
  );

  const onSubmit: SubmitHandler<Inputs> = (data) => {
    const code = data.code.replaceAll(' ', '');
    sendRecoveryCode.mutate({ code });
  };
  const resendCodeHandler = () => {
    const email = localStorage.getItem(STORAGE_KEYS.EMAIL);
    if (!email) return;

    resendCode.mutate({ email });
  };

  const codeRegister = register.bind(null, 'code', {
    required: { message: CodeErrors.required, value: true },
    minLength: {
      value: AUTH_VALIDATION_CONFIG.sixDigitCode.length,
      message: CodeErrors.incorrect
    },
    maxLength: {
      value: AUTH_VALIDATION_CONFIG.sixDigitCode.length,
      message: CodeErrors.incorrect
    }
  });
  const positionStyles = errors.code ? 'bottom-14' : 'bottom-5';
  return (
    <>
      <FormCaption> Password recovery </FormCaption>
      <InformSpan>
        A password reset code has been sent to your email{' '}
        <span className="text-black font-semibold">
          {localStorage.getItem(STORAGE_KEYS.EMAIL)}
        </span>
        , enter it in the field below:
      </InformSpan>
      <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col mt-7">
        <SixDigitInput
          errors={errors}
          name="code"
          formattedValue={formattedValue}
          register={codeRegister}
          setFormattedValue={setFormattedValue}
        >
          {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
           */}
          <div
            className={`absolute flex right-4 ${positionStyles} text-blue-500 hover:text-blue-700  cursor-pointer`}
            onClick={resendCodeHandler}
          >
            <span>Resend code </span> <ArrowClock />
          </div>
        </SixDigitInput>

        <FormSubmit value="Confirm" />

        <ArrowLink to={ROUTER_KEYS.FORGOT_PASSWORD} linkText="Change email" />
      </form>
    </>
  );
};
