import React from 'react';

interface Props {
  children: React.ReactNode;
}

export const FileLoadContainer = ({ children }: Props) => (
  <div className="-ml-1 bg-white p-7 sm:mx-auto rounded-lg flex flex-col gap-4 w-full lg:w-7/12 shadow">
    {children}
  </div>
);
