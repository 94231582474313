import React from 'react';

export const EmaiIcon = () => (
  <svg
    width="19"
    height="19"
    viewBox="0 0 19 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className=" fill-current"
  >
    <g clipPath="url(#clip0_140_5511)">
      <path
        // eslint-disable-next-line max-len
        d="M12.1632 9.74707L10.6556 11.2597C10.0443 11.8732 8.96866 11.8864 8.34411 11.2597L6.8365 9.74707L1.42188 15.1793C1.62343 15.2725 1.84565 15.3281 2.0819 15.3281H16.9178C17.1541 15.3281 17.3762 15.2725 17.5777 15.1793L12.1632 9.74707Z"
      />
      <path
        // eslint-disable-next-line max-len
        d="M16.9178 2.67188H2.08183C1.84558 2.67188 1.62336 2.72749 1.42188 2.82069L7.20779 8.6258C7.20817 8.62618 7.20863 8.62625 7.20902 8.62664C7.2094 8.62703 7.20947 8.62756 7.20947 8.62756L9.09079 10.5151C9.29062 10.7149 9.70905 10.7149 9.90887 10.5151L11.7898 8.62787C11.7898 8.62787 11.7903 8.62703 11.7906 8.62664C11.7906 8.62664 11.7915 8.62618 11.7919 8.6258L17.5776 2.82066C17.3762 2.72742 17.154 2.67188 16.9178 2.67188Z"
      />
      <path
        // eslint-disable-next-line max-len
        d="M0.668258 3.55859C0.563984 3.76946 0.5 4.00353 0.5 4.25427V13.7465C0.5 13.9972 0.563914 14.2313 0.668223 14.4421L6.09223 9.00054L0.668258 3.55859Z"
      />
      <path
        // eslint-disable-next-line max-len
        d="M18.3321 3.55859L12.9082 9.00061L18.3321 14.4423C18.4364 14.2314 18.5004 13.9973 18.5004 13.7465V4.25434C18.5004 4.00353 18.4364 3.76946 18.3321 3.55859Z"
      />
    </g>
    <defs>
      <clipPath id="clip0_140_5511">
        <rect width="18" height="18" fill="white" transform="translate(0.5)" />
      </clipPath>
    </defs>
  </svg>
);
