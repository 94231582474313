import React, { useRef } from 'react';
import { AvailablePlugins } from './avaiable-plugins.component';
import { SubscriptionsPlans } from './subscription-plans.component';

export const HomePageContainer = () => {
  const targetRef = useRef<null | HTMLDivElement>(null);
  const ButtonClickHandler = () => {
    if (targetRef.current === null) return;

    targetRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <div>
      <AvailablePlugins onClick={ButtonClickHandler} />
      <SubscriptionsPlans refScroll={targetRef} />
    </div>
  );
};
