import React from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { SmallButton } from '../ui/small-button.component';
import { FormContainer, Input, PopupContainer } from '../../common/components';
import {
  AUTH_PATTERN,
  AUTH_VALIDATION_CONFIG,
  EmailErrors,
  PasswordErrors
} from '../../auth/forms/validation.pattern';
import { PopupButtons } from '../../common/components/popup';
import { IPopupProps } from './types.type';
import { settingsService } from '../service/settings.service';
import usePopup from '../../common/feature/popup';
import { ConfirmEmail } from './confirm-email.component';
import { STORAGE_KEYS } from '../../common/consts/app-keys.const';

type Inputs = {
  email: string;
};

export const EditEmail = ({ isOpen, onClose }: IPopupProps) => {
  const { handleModal } = usePopup();
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors }
  } = useForm<Inputs>();

  const email = useMutation(
    async (data: Inputs) => settingsService.changeEmail({ newEmail: data.email }),
    {
      onSuccess: () => {
        onClose();
        localStorage.setItem(STORAGE_KEYS.EMAIL, watch('email'));
        handleModal(ConfirmEmail);
      },
      onError: (err: any) => {
        if (err.response.data.message === 'Email already exists') {
          errors.email = {
            message: EmailErrors.taken,
            type: 'manual'
          };
        }
        if (err.response.data.message === 'Same email') {
          errors.email = {
            message: EmailErrors.same,
            type: 'manual'
          };
        }
      }
    }
  );

  const onSubmit: SubmitHandler<Inputs> = (data: Inputs) => {
    email.mutate(data);
  };

  return (
    <PopupContainer
      caption="Change email address"
      isOpen={isOpen}
      onClose={onClose}
      description="Your new email must be different to previously used email."
    >
      <FormContainer onSubmit={handleSubmit(onSubmit)}>
        <Input
          errors={errors}
          name="email"
          label="Email"
          placeholder="New email address"
          type="email"
          register={register.bind(null, 'email', {
            required: { message: EmailErrors.required, value: true },
            pattern: {
              value: AUTH_PATTERN.email,
              message: EmailErrors.pattern
            }
          })}
        />

        <PopupButtons onClose={onClose} text="Update email" />
      </FormContainer>
    </PopupContainer>
  );
};
