import React from 'react';
import { useHistory } from 'react-router-dom';
import { PopupContainer } from '../../common/components';
import { PopupButtons } from '../../common/components/popup';
import { ROUTER_KEYS, STORAGE_KEYS } from '../../common/consts/app-keys.const';
import { IPopupProps } from './types.type';
import useAuth from '../../auth/feature/auth';
import { queryClient } from '../../app/queryClient';

export const Logout = ({ isOpen, onClose }: IPopupProps) => {
  const { setIsAuthenticated } = useAuth();
  const history = useHistory();

  const logoutHandler = () => {
    setIsAuthenticated(false);
    localStorage.removeItem(STORAGE_KEYS.EMAIL);
    localStorage.removeItem(STORAGE_KEYS.TOKEN);
    onClose();
    queryClient.clear();
    history.push(ROUTER_KEYS.ROOT);
  };

  return (
    <PopupContainer
      caption="Are you sure you want to log out?"
      isOpen={isOpen}
      onClose={onClose}
    >
      <PopupButtons onClick={logoutHandler} text="Log out" onClose={onClose} />
    </PopupContainer>
  );
};
