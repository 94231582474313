import React from 'react';
import { ManagementTableDesktop } from './management-table.component';
import { Ikey } from '../../types.type';
import { ManagementTableMobile } from './management-table-mobile.component';

interface IProps {
  data: Ikey[];
}

export const ManagementTable = ({ data }: IProps) => {
  const [width, setWidth] = React.useState(window.innerWidth);

  React.useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return width > 640 ? (
    <ManagementTableDesktop data={data} />
  ) : (
    <ManagementTableMobile data={data} />
  );
};
