import React from 'react';
import { ArrowClock } from '../icons';

interface IProps {
  onClick: () => void;
  error: boolean;
}

export const ResendCodeButton = ({ onClick, error }: IProps) => {
  const positionStyles = error ? 'bottom-14' : 'bottom-5';

  /* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */
  return (
    <div
      className={`absolute right-4 ${positionStyles} text-blue-500 stroke-blue-500 hover:text-blue-400 hover:stroke-blue-400 active:text-blue-700 active:stroke-blue-700 cursor-pointer flex gap-2`}
      onClick={onClick}
    >
      <span>Resend code </span>
      <ArrowClock />
    </div>
  );
};
