import React from 'react';

interface IProps {
  text: string;
  icon: React.ReactNode;
  isReverse?: boolean;
  fill: boolean;
  onClick?: () => void;
  disabled?: boolean;
}

export const MenuButton = ({
  text,
  icon,
  isReverse,
  fill,
  onClick,
  disabled
}: IProps) => {
  const content = isReverse ? (
    <>
      <span>{text}</span>
      {icon}
    </>
  ) : (
    <>
      {icon}
      <span>{text}</span>
    </>
  );
  const colors = fill
    ? 'bg-blue-600 text-white hover:bg-blue-500  active:bg-blue-700'
    : 'border-2 text-gray-500 border-gray-500 hover:border-blue-400 active:text-blue-700 active:border-blue-700 hover:text-blue-500';

  const disabledSyles = disabled
    ? 'opacity-40 cursor-not-allowed bg-blue-800 hover:bg-blue-800 active:bg-blue-800'
    : '';
  return (
    <button
      disabled={disabled}
      type="button"
      className={`flex justify-center w-full sm:w-3/4 gap-2 py-2 px-1 rounded-xl min-w-fit ${colors} ${disabledSyles}`}
      onClick={onClick}
    >
      {content}
    </button>
  );
};
