import React, { useEffect } from 'react';
import { MainRouter } from '../navigation';
import { Providers } from './providers';
import useAuth from '../auth/feature/auth';
import '../../index.css';

function AppContainer() {
  const { checkAuth } = useAuth();
  useEffect(() => {
    checkAuth();
  }, []);

  return (
    <Providers>
      <MainRouter />
    </Providers>
  );
}

export default AppContainer;
