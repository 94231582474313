import React, { useEffect, useState } from 'react';
import { IInputProps } from '../../types/input.type';
import { ErrorSpan } from './error-span.component';
import eyeSlashSvg from '../../../../assets/icons/eye-slash.svg';
import eyeOpenSvg from '../../../../assets/icons/eye-open.svg';

export const Input = ({
  placeholder,
  errors,
  name,
  register,
  type,
  label,
  children
}: IInputProps) => {
  const [isPassword, setIsPassword] = React.useState(false);
  const [inputType, setInputType] = React.useState(type);
  const [eyeSvg, setEyeSvg] = useState(eyeOpenSvg);

  useEffect(() => {
    if (type === 'password') {
      setIsPassword(true);
    }
  }, [type]);

  const togglePassword = () => {
    if (inputType === 'password') {
      setInputType('text');
      setEyeSvg(eyeSlashSvg);
    } else {
      setInputType('password');
      setEyeSvg(eyeOpenSvg);
    }
  };

  return (
    <div className="relative">
      <label htmlFor={name} className="capitalize">
        {label}
      </label>
      <input
        placeholder={placeholder}
        type={inputType}
        className={errors[`${name}`] && 'errorInput'}
        {...register()}
      />
      {isPassword && (
        <img
          src={eyeSvg}
          alt="show password"
          onClick={togglePassword}
          className="absolute right-4 top-10 h-8 cursor-pointer hover:opacity-80"
        />
      )}
      {children}
      {errors[`${name}`] && <ErrorSpan>{errors[`${name}`].message}</ErrorSpan>}
    </div>
  );
};
