import React from 'react';

export const KeyIcon = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    className="fill-current"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      // eslint-disable-next-line max-len
      d="M15.6532 5.04612C15.6532 4.36489 15.5192 3.7047 15.2571 3.08087C15.0026 2.48001 14.639 1.94037 14.1759 1.47729C13.7128 1.0142 13.1732 0.650619 12.5723 0.396112C11.9504 0.132037 11.2883 0 10.6071 0C9.92583 0 9.26564 0.133951 8.64181 0.396112C8.04097 0.650619 7.50132 1.0142 7.03823 1.47729C6.57514 1.94037 6.21156 2.48001 5.95705 3.08087C5.69298 3.70279 5.56094 4.36489 5.56094 5.04612C5.56094 5.89767 5.77526 6.73199 6.18286 7.47446L0.14167 13.5099C0.00197798 13.6496 -0.0420345 13.862 0.0440768 14.0629C0.0612991 14.105 0.0900029 14.1433 0.122534 14.1758L1.47544 15.5287C1.50797 15.5613 1.54624 15.5881 1.58834 15.6072C1.78927 15.6933 2.00167 15.6512 2.14137 15.5096L2.9776 14.6733L4.18125 15.877C4.21378 15.9095 4.25205 15.9363 4.29413 15.9555C4.49506 16.0416 4.70748 15.9995 4.84716 15.8579L6.18092 14.5241C6.21345 14.4916 6.24024 14.4533 6.25938 14.4112C6.34549 14.2103 6.30339 13.9979 6.16179 13.8582L4.97728 12.6737L8.17679 9.47224C8.91735 9.87984 9.75359 10.0942 10.6051 10.0942C11.2864 10.0942 11.9466 9.96021 12.5704 9.69805C13.1712 9.44354 13.7109 9.07996 14.174 8.61687C14.637 8.1538 15.0006 7.61417 15.2551 7.01329C15.5192 6.38755 15.6532 5.72736 15.6532 5.04612ZM12.3752 6.81619C11.9026 7.28884 11.273 7.54909 10.6052 7.54909C9.93733 7.54909 9.30774 7.28884 8.8351 6.81619C8.36245 6.34353 8.10029 5.71396 8.10029 5.04612C8.10029 4.37637 8.36053 3.74871 8.83319 3.27606C9.30585 2.8034 9.93544 2.54315 10.6033 2.54315C11.2711 2.54315 11.9007 2.8034 12.3733 3.27606C12.846 3.74871 13.1062 4.37828 13.1062 5.04612C13.11 5.71396 12.8498 6.34353 12.3752 6.81619Z"
    />
  </svg>
);
