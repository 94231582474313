import React from 'react';
import { CancelIcon } from '../icons/cancel-icon.component';
import { InformSpan } from '../form';

interface IProps {
  children: React.ReactNode;
  onClose: () => void;
  isOpen: boolean;
  caption: string;
  description?: string | React.ReactNode;
}
export const PopupContainer = ({
  children,
  isOpen,
  onClose,
  caption,
  description
}: IProps) => (
  <div
    className={`${
      isOpen ? '' : 'hidden'
    } absolute top-0 w-screen h-screen z-50 flex align-middle justify-center flex-col pr-9 pl-4`}
  >
    {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
    <div
      className="w-screen h-screen bg-blue-100 opacity-50 absolute"
      onClick={onClose}
    />
    <div className="bg-white rounded-lg p-11 flex flex-col justify-center mx-4 relative my-20 md:mx-auto w-full md:w-1/2 lg:w-1/3">
      <div>
        <h1 className="text-4xl font-semibold text-center mb-3">{caption}</h1>
        {description && (
          <div className="text-gray-500 text-lg text-center">{description}</div>
        )}
      </div>
      {children}
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
      <div onClick={onClose} className="absolute top-5 right-5 cursor-pointer">
        <CancelIcon />
      </div>
    </div>
  </div>
);
