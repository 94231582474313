import React from 'react';
import Lottie from 'react-lottie';
import loadingAnimation from '../../../assets/animations/loader-animation.json';

export const Loader = () => {
  const options = { autoplay: true, loop: true, animationData: loadingAnimation };

  return (
    <div className="h-full w-full flex justify-center align-middle bg-blue-300  fixed z-50">
      <Lottie options={options} />
    </div>
  );
};
