import React from 'react';

interface IProps {
  text: string | React.ReactNode;
  type: 'danger' | 'default' | 'filled' | 'gray';
  buttonType?: 'button' | 'submit' | 'reset';
  onClick?: () => void;
}

export const SmallButton = ({ text, type, buttonType, onClick }: IProps) => {
  const classes = {
    gray: 'border-gray-500 text-gray-500 hover:border-blue-400 hover:text-blue-400 active:text-blue-700 active:border-blue-700',
    danger:
      'border-gray-500 text-gray-500 hover:border-red-500 hover:text-red-500 active:bg-hover:text-red-500 active:bg-rose-100',
    default:
      'border-blue-600 text-blue-600  hover:border-blue-400 hover:text-blue-400 active:text-blue-700 active:border-blue-700',
    filled:
      'border-blue-600 bg-blue-600 text-white hover:text-white hover:bg-blue-400 hover:border-blue-400 active:text-white active:bg-blue-700 active:border-blue-700'
  };
  let width = 'min-w-fit';
  if (typeof text === 'string') {
    width = 'w-56';
  }

  return (
    <button
      onClick={onClick || undefined}
      type={buttonType ? 'submit' : 'button'}
      className={`border-2 rounded-xl px-2 py-2 ${classes[type]} ${width}`}
    >
      {text}
    </button>
  );
};
