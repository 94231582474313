import React from 'react';

interface Props {
  children: React.ReactNode;
}

export const DropdownContainer = ({ children }: Props) => (
  <div className="z-50 bg-white shadow p-4 absolute rounded-lg flex flex-col gap-2 right-10 top-16">
    {children}
  </div>
);
